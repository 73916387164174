import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Image from "../../assets/img/svg2.svg";
import axios from "../../API/axios";

function CompleteRegistration() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    confirm_password: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError(false);
  };

  const CompleteRegistration = (e) => {
    e.preventDefault();
    const passwordMatch = credentials.password === credentials.confirm_password;
    if (!passwordMatch)
      return setError({ error: true, message: "Password does not match" });
    else {
      const data = {
        username: credentials.username,
        password: credentials.password,
      };
      setLoading(true);
      axios
        .post(`web/manage/complete-registration/${id}/`, data)
        .then((res) => {
          navigate("/login");
        })
        .catch((err) => {
          console.error(err);
          setError({
            error: true,
            message: err.response.data.message,
          });
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div
        className="flex z-10 h-screen w-full items-center absolute
     justify-center bg-cover bg-no-repeat  
       "
        style={{
          backgroundImage: `url(${Image})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="flex z-20 h-screen w-full items-center relative
     justify-center   
     "
      >
        <div
          className="flex items-center  relative z-20 md:rounded-3xl shadow-2xl h-screen  md:h-fit w-full  
           px-16 py-20 md:mx-auto md:max-w-lg  backdrop-blur-md border border-primary-300 bg-primary-400 bg-opacity-45
            border-secondary-100 border-opacity-50"
        >
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-semibold text-center text-white">
                Mubarti
              </h2>
            </div>

            <>
              <div className="text-center">
                <p className="mt-2  text-sm text-slate-400 capitalize">
                  Complete Registration
                </p>
              </div>

              <div className="mt-6">
                <form onSubmit={CompleteRegistration}>
                  <div>
                    <label
                      htmlFor="username"
                      className="block mb-2 text-xs text-white  tracking-widest"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="username"
                      id="username"
                      placeholder="Enter Your username"
                      className={`block w-full text-sm px-4 py-2 mt-2 
                    placeholder-gray-400 bg-white 
                    rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                     focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
                     ${
                       error.error ? "ring ring-rose-600 border-rose-600" : ""
                     }`}
                    />
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-xs text-white  tracking-widest"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      onChange={handleChange}
                      name="password"
                      id="password"
                      placeholder="Enter Your Password"
                      className={`block w-full text-sm px-4 py-2 mt-2 
                    placeholder-gray-400 bg-white 
                    rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                     focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
                     ${
                       error.error ? "ring ring-rose-600 border-rose-600" : ""
                     }`}
                    />
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="confirm_password"
                      className="block mb-2 text-xs text-white  tracking-widest"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      onChange={handleChange}
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="Re-Enter Your Password"
                      className={`block w-full text-sm px-4 py-2 mt-2 
                    placeholder-gray-400 bg-white 
                    rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                     focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
                     ${
                       error.error ? "ring ring-rose-600 border-rose-600" : ""
                     }`}
                    />
                  </div>

                  {error ? (
                    <div>
                      <p className="text-xs tracking-wider text-rose-500 font-semibold mt-4">
                        {error.message}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mt-10">
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full px-4 py-2 tracking-wide text-white font-medium shadow-sm transition-colors duration-200
                 transform bg-secondary-400 rounded-md hover:bg-secondary-100 focus:outline-none
                 disabled:bg-gray-400 focus:bg-secondary-400 focus:ring focus:ring-secondary-300 focus:ring-opacity-60"
                    >
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompleteRegistration;
