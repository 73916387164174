import axios from "axios";
import i18n from 'i18next'; // import i18n to get the current language

const BASE_URL = "https://api.mubarti.streamline.com.ly/api/v1/web";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const axiosPrivateInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to add the Accept-Language header dynamically
const setLanguageHeader = (config) => {
  const currentLanguage = i18n.language || 'en'; // get the current language (default to 'en' if not set)
  config.headers['Accept-Language'] = currentLanguage === 'ar' ? 'ar' : 'en';
  return config;
};

// Apply the interceptor to add the Accept-Language header before each request
axiosInstance.interceptors.request.use(setLanguageHeader, (error) => Promise.reject(error));
axiosPrivateInstance.interceptors.request.use(setLanguageHeader, (error) => Promise.reject(error));

export default axiosInstance;
export { axiosPrivateInstance as axiosPrivate };
