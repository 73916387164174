/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "../../../components/icons/DashIcon";
import { useTranslation } from 'react-i18next'; // Importing useTranslation for translations

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes, onClose, userPermissions } = props;

  const { t } = useTranslation(); // Use translation hook to translate route names

  // Verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      onClose(); // Close sidebar only on mobile screens
    }
  };

  const createLinks = (routes) => {
    return routes
      .filter((route) => {
        // Check user permissions before rendering the link
        if (route.name === "Users" && !userPermissions.has_user_permission) return false;
        if (route.name === "Surveys" && !userPermissions.has_survey_permission) return false;
        if (route.name === "Settings" && !userPermissions.has_settings_permission) return false;
        if (route.name === "Activities" && !userPermissions.has_activity_permission) return false;
        if (route.name === "Dashboard" && !userPermissions.has_dashboard_permission) return false;
        return route.includedInSidebar;
      })
      .map((route, index) => (
        <Link key={index} to={route.layout + "/" + route.path} onClick={handleLinkClick}>
          <div className="relative mb-6 flex hover:cursor-pointer">
            <li className="my-[3px] flex cursor-pointer items-center px-8" key={index}>
              <span className={`${activeRoute(route.path) ? "font-bold font-tajawal text-brand-500 dark:text-pgBrand-500" : "font-medium text-gray-600"}`}>
                {route.icon ? route.icon : <DashIcon />}{" "}
              </span>
              <p className={`leading-1 ml-4 text-lg flex ${activeRoute(route.path) ? "font-bold font-tajawal text-navy-700 dark:text-pgBrand-500" : "font-medium text-gray-600"}`}>
                {t(route.name)} {/* Added translation for route name */}
              </p>
            </li>
            {activeRoute(route.path) ? <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-pgBrand-500" /> : null}
          </div>
        </Link>
      ));
  };

  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
