import React, { useEffect, useState } from "react";
import axios from "../../API/axios";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function SignIn() {
  const navigate = useNavigate();
  const { setAuth, persist, setPersist } = useAuth();

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (credentials.username === "" || credentials.password === "")
      return setError({ error: true, message: "All fields are required" });
    else {
      axios
        .post("/auth/login/", credentials)
        .then((res) => {
          //  console.log(res);
          const access_token = res.data.data.access_token;
          const refresh_token = res.data.data.refresh_token;
          const user = res.data.data.user;
          setAuth({ user, access_token, refresh_token });
          navigate("/dashboard", { replace: true });
        })
        .catch((err) => {
          console.error(err);
          setError({
            error: true,
            message: " Invalid Credentials, Please Try Again.",
          });
          setLoading(false);
        });
    }
  };

  const togglePersist = () => {
    setPersist((persist) => !persist);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <div className="flex-1">
      <div className="text-center">
        <h2 className="text-4xl  text-center text-white">Mubarti</h2>

        <p className="mt-2  text-slate-400 ">Sign in to access your account</p>
      </div>

      <div className="mt-8">
        <form onSubmit={handleLogin}>
          <div>
            <label
              htmlFor="username"
              className="block mb-2 text-xs text-white  tracking-widest"
            >
              Username
            </label>
            <input
              type="text"
              onChange={handleChange}
              name="username"
              id="username"
              placeholder="Username"
              className={`block w-full text-sm px-4 py-2 mt-2 
         placeholder-gray-400 bg-white 
         rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
          focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
          ${error.error ? "ring ring-rose-600  border-error" : ""}`}
            />
          </div>

          <div className="mt-6">
            <div className="flex justify-between mb-2">
              <label
                htmlFor="password"
                className="text-xs text-white  tracking-widest"
              >
                Password
              </label>
              <button
                onClick={() => navigate("/ResetPassword")}
                className="text-xs text-gray-400 tracking-widest hover:text-lightBlue-400 "
              >
                Forgot password?
              </button>
            </div>

            <input
              type="password"
              name="password"
              onChange={handleChange}
              id="password"
              placeholder="Your Password"
              className={`block w-full text-sm px-4 py-2 mt-2 
            placeholder-gray-400 bg-white 
            rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
             focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
             ${error.error ? "ring ring-rose-600 border-error" : ""}`}
            />
          </div>

          {error.error ? (
            <div>
              <p className="text-xs font-semibold tracking-wider text-rose-500 mt-4">
                {error.message}
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="items-center mt-3 flex">
            <input
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
              className="h-3 w-3 cursor-base focus:ring-blue rounded border-gray-300"
            />
            <label htmlFor="persist " className="text-xs text-gray-600 ml-2">
              Remember Me
            </label>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              disabled={loading}
              className="w-full px-4 py-2 tracking-wide text-white font-medium shadow-sm transition-colors duration-200
         transform bg-secondary-400 rounded-md hover:bg-secondary-100 focus:outline-none
         disabled:bg-gray-400 focus:bg-secondary-400 focus:ring focus:ring-secondary-300 focus:ring-opacity-60"
            >
              Sign in
            </button>
          </div>
        </form>

        <p className="mt-6 text-sm text-center  text-slate-400">
          Don&#x27;t have an account yet? &nbsp;
          <a
            href="#"
            className="text-lightBlue-400 focus:outline-none focus:underline hover:text-secondary-100"
          >
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
}

export default SignIn;
