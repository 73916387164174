import Card from "../../../../components/card";
import { LanguageToggleButton } from '../../../../App';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  MdOutlineError,
  MdBlockFlipped,
  MdOutlineCancel,
} from "react-icons/md";
import { LuUserX2 } from "react-icons/lu";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { HiX } from "react-icons/hi";

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../../components/dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FiArchive, FiEdit2 } from "react-icons/fi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import the translation hook

const StatusesTable = (props) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation(); // Initialize translation hook

  const { transparent } = false;
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [editable, setEditable] = useState();
  const [addNewForm, setAddNewForm] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false); // For Edit Role modal
  const [editableRole, setEditableRole] = useState(null); // For storing the role being edited
  const [newRole, setNewRole] = useState({
    name: "",
    description: "",
    permissions: {},
  });
  const [permissionLabels, setPermissionLabels] = useState({}); // State to store permission labels

  // Fetch roles on component mount
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosPrivate.get("/manage/roles/");
        setRoles(response.data.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  useEffect(() => {
    const fetchPermissionLabels = async () => {
      try {
        const response = await axiosPrivate.get("/manage/permissions/");
        setPermissionLabels(response.data.data); // Store permission labels in state
      } catch (error) {
        console.error("Error fetching permission labels:", error);
      }
    };

    fetchPermissionLabels();
  }, []);

  const fetchRole = async (roleId) => {
    try {
      const response = await axiosPrivate.get(`/manage/${roleId}/view-role/`);
      setEditableRole(response.data.data); // Set the role data in state
      setEditModalOpen(true); // Open the modal
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };
  
  const handleUpdateRole = async () => {
    try {
      await axiosPrivate.patch(`/manage/${editableRole.id}/update-role/`, editableRole);
      setEditModalOpen(false); // Close modal after saving
  
      Swal.fire({
        title: "Success!",
        text: "Role updated successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
  
      // Re-fetch the roles to update the table
      const response = await axiosPrivate.get("/manage/roles/");
      setRoles(response.data.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the role.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error updating role:", error);
    }
  };
  

  // Handle role creation
  const handleCreateRole = async () => {
    try {
      await axiosPrivate.post("/manage/create-role/", newRole);
      setModalOpen(false); // Close modal after creating the role

      Swal.fire({
        title: "Success!",
        text: "Role created successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });

      // Re-fetch the roles to update the table
      const response = await axiosPrivate.get("/manage/roles/");
      setRoles(response.data.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "There was an error creating the role.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error creating role:", error);
    }
  };
  

  const handleNewRoleCheckboxChange = (permission) => {
    setNewRole((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [permission]: !prev.permissions[permission], // Toggle the permission state
      },
    }));
  };
  
  // This function is already present for editable roles:
  const handleCheckboxChange = (permission) => {
    setEditableRole((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [permission]: !prev.permissions[permission], // Toggle the permission state
      },
    }));
  };
  

  // Fetch statuses
  const getStatuses = () => {
    axiosPrivate.get("/facility/status/").then((res) => {
      setStatuses(res.data.data);
    });
  };


  useEffect(() => {
    getStatuses();
  }, []);

  const deleteStatus = (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: "Delete!",
      background: "#091442",
      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate
          .delete(`/facility/${id}/delete-status/`)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Status has been deleted successfully!",
              confirmButtonText: "Ok",
              confirmButtonColor: "#34EDFF",
              background: "#091442",
              color: "white",
            }).then(() => {
              getStatuses();
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              confirmButtonText: "Go Back",
              confirmButtonColor: "#45acc4",
            });
          });
      }
    });
  };

  const createStatus = (data) => {
    axiosPrivate
      .post(`/facility/create-status/`, data)
      .then((res) => {
        setAddNewForm(false);
        Swal.fire({
          icon: "success",
          title: "Status has been created successfully.",
          confirmButtonText: "Ok",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then(() => {
          getStatuses();
        });
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Go Back",
          confirmButtonColor: "#45acc4",
        });
      });
  };

  const editStatus = (data) => {
    axiosPrivate
      .patch(`/facility/${editable}/update-status/`, data)
      .then((res) => {
        setEditable();
        Swal.fire({
          icon: "success",
          title: "Statuses have been updated successfully.",
          confirmButtonText: "Ok",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then(() => {
          getStatuses();
        });
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Go Back",
          confirmButtonColor: "#45acc4",
        });
      });
  };

  return (
    <Card
      extra={
        "w-full h-full p-4 sm:overflow-x-auto bg-!pgBrand-300 mb-10 overflow-y-visible"
      }
    >
      {/* Statuses Section */}
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {t("Statuses")}
        </div>
        <button
          onClick={() => {
            setAddNewForm(true);
            setEditable();
          }}
          className="linear group w-fit rounded-[20px] flex items-center space-x-3 bg-pgBrand-500 px-8 py-2 font-medium text-white  
          duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
          dark:hover:bg-pgBrand-550 dark:active:opacity-90"
        >
          <FaPlus className="group-hover:rotate-90 transition-transform duration-300 " />
          <span className="uppercase tracking-wider text-xs ">{t("Add Status")} </span>
        </button>
      </div>

      <div className="mt-8 h-fit overflow-x-scroll xl:overflow-x-hidden ">
        <Formik initialValues={{}} onSubmit={editStatus}>
          <Form>
            <table className="w-full h-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-6 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-pgBrand-200">#</p>
                  </th>
                  <th className="border-b w-[75%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-pgBrand-200">
                      {t("Name")}
                    </p>
                  </th>
                  <th className="border-b px-8 border-gray-200 flex pb-[10px] justify-end dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-pgBrand-200">
                      {t("Action")}
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {statuses.map((status, index) => {
                  return (
                    <tr
                      className="h-14 border-b border-gray-100 border-opacity-10 "
                      key={status.id}
                    >
                      <td className="w-[5%]">
                        <p className="text-sm font-bold text-navy-700 dark:text-pgBrand-200">
                          {index + 1}
                        </p>
                      </td>
                      <td>
                        {editable === status.id ? (
                          <div className="relative ">
                            <input
                              type="text"
                              required
                              defaultValue={status.name}
                              id="name"
                              name="name"
                              className="block px-2.5 py-2 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                              placeholder=""
                            />
                            <label
                              htmlFor="name"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              {t("Name")}
                            </label>
                          </div>
                        ) : (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {status.name}
                          </p>
                        )}
                      </td>

                      <td className="lg:px-8 px-2 flex mt-2 justify-end items-center ">
                        {editable === status.id ? (
                          <div className="flex mt-1 lg:mt-0">
                            <Button
                              variant="text"
                              color="red"
                              type="button"
                              onClick={() => setEditable()}
                              className="normal-case text-xs mr-2 px-1 md:px-8 !py-2 border-2 border-red-600 duration-200 dark:active:opacity-90 disabled:border-red-600"
                              >
                              <span>{t("Cancel")}</span>
                            </Button>
                            <Button
                              type="submit"
                              className="normal-case text-xs px-1 md:px-8 !py-2 bg-pgBrand-500"
                              >
                              <span>{t("Submit")}</span>
                            </Button>
                          </div>
                        ) : (
                          <Dropdown
                            button={
                              <button
                                onClick={() => setOpen(!open)}
                                open={open}
                                type="button"
                                className={`flex items-center text-xl  hover:cursor-pointer  ${
                                  transparent
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                } linear justify-center rounded-lg font-bold transition duration-200`}
                              >
                                <BsThreeDots className="h-6 w-6" />
                              </button>
                            }
                            animation={
                              "origin-top-right transition-all duration-300 ease-in-out"
                            }
                            classNames={`!z-50 ${
                              transparent ? "top-8" : "top-11"
                            } right-0 w-max `}
                            children={
                              <div className="!z-50 block  w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <p
                                  onClick={() => {
                                    setEditable(status.id);
                                    setAddNewForm(false);
                                  }}
                                  className="hover:text-pgBrand-500 flex cursor-pointer items-center gap-2 text-gray-400 hover:font-medium"
                                >
                                  <span>
                                    <FiEdit2 />
                                  </span>
                                  {t("Edit")}
                                </p>

                                <p
                                  onClick={() => deleteStatus(status.id)}
                                  className="hover:text-red-600 mt-2 flex cursor-pointer
                             items-center gap-2 pt-1 text-red-500 hover:font-semibold font-medium"
                                >
                                  <span>
                                    <MdBlockFlipped />
                                  </span>
                                  {t("Delete")}
                                </p>
                              </div>
                            }
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Form>
        </Formik>

        {addNewForm ? (
          <Formik initialValues={{ name: "" }} onSubmit={createStatus}>
            <Form>
              <div>
                <tr className="h-14 flex w-full items-center justify-between ">
                  <td className="w-[5%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-pgBrand-200">
                      #
                    </p>
                  </td>
                  <td className=" w-[75%] me-auto">
                    <div className="relative ">
                      <Field
                        type="text"
                        required
                        id="name1"
                        name="name"
                        className="block px-2.5 py-2 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=""
                      />
                      <label
                        htmlFor="name1"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Name")}
                      </label>
                    </div>
                  </td>

                  <td className="lg:px-8 px-2 flex mt-2 justify-end items-center ">
                    <div className="flex">
                      <Button
                        variant="text"
                        color="red"
                        type="button"
                        onClick={() => setAddNewForm(false)}
                        className="normal-case text-xs mr-2 px-1 md:px-8 !py-2 border-2 border-red-600 duration-200 dark:active:opacity-90 disabled:border-red-600"
                      >
                        <span>{t("Cancel")}</span>
                      </Button>
                      <Button
                        type="submit"
                        className="normal-case text-xs px-1 md:px-8 !py-2 bg-pgBrand-500"
                      >
                        <span>{t("Submit")}</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              </div>
            </Form>
          </Formik>
        ) : (
          ""
        )}
      </div>

      {/* New Roles Section */}
      <div className="mt-10">
        {/* Header with Add Role button */}
        <div className="relative flex items-center justify-between">
          <div className="text-xl font-bold text-navy-700 dark:text-white">{t("Roles")}</div>
          <button
            onClick={() => setModalOpen(true)} // Open modal
            className="linear group w-fit rounded-[20px] flex items-center space-x-3 bg-pgBrand-500 px-8 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 dark:hover:bg-pgBrand-550 dark:active:opacity-90"
          >
            <FaPlus className="group-hover:rotate-90 transition-transform duration-300 " />
            <span className="uppercase tracking-wider text-xs">{t("Add Role")}</span>
          </button>
        </div>

        {/* Modal for creating new role */}
        {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
          <div className="dark:bg-navy-700 p-6 rounded-lg shadow-lg w-[600px] border border-pgBrand-200">
            <h2 className="text-xl font-bold mb-4">{t("Create New Role")}</h2>

            <label className="font-bold text-white text-2xl">{t("Role Name")}</label>
            <input
              type="text"
              placeholder="Role Name"
              value={newRole.name}
              onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
              className="bg-navy-700 w-full mb-4 p-2 border border-pgBrand-500 rounded"
            />
            <label className="font-bold text-white text-2xl">{t("Role Description")}</label>
            <textarea
              placeholder="Role Description"
              value={newRole.description}
              onChange={(e) => setNewRole({ ...newRole, description: e.target.value })}
              className="bg-navy-700 w-full mb-4 p-2 border border-pgBrand-500 rounded"
            />

            {/* Permissions - Dynamically generated checkboxes */}
            <div className="grid grid-cols-3 gap-4 max-h-60 overflow-y-auto">
              {Object.keys(permissionLabels).map((permission) => (
                <label className="flex items-center" key={permission}>
                  <input
                    type="checkbox"
                    checked={newRole.permissions[permission] || false}
                    onChange={() => handleNewRoleCheckboxChange(permission)}
                    className="mr-2 w-5 h-5 text-pgBrand-500 border-pgBrand-200 focus:ring-pgBrand-500 cursor-pointer" // Ensure size and cursor style
                  />
                  <span className="text-sm">{t(permissionLabels[permission])}</span> {/* Translate and format label */}
                </label>
              ))}
            </div>

            <div className="flex justify-between w-full !mt-6">
              <button
                className="flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-red-600 border-2 border-red-600 duration-200 text-sm dark:active:opacity-90 disabled:border-red-600 mr-1 normal-case"
                onClick={() => setModalOpen(false)} // Close modal
              >
                {t("Cancel")}
              </button>
              <button
                className="flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-white text-sm bg-pgBrand-500 mr-1 normal-case"
                onClick={handleCreateRole}
              >
                {t("Submit")}
              </button>
            </div>
          </div>
        </div>
        )}


        {/* Roles Table */}
        <div className="mt-8 h-fit overflow-x-scroll xl:overflow-x-hidden ">
          <table className="w-full h-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-6 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-pgBrand-200">#</p>
                </th>
                <th className="border-b w-[75%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-pgBrand-200">{t("Role Name")}</p>
                </th>
                <th className="border-b px-8 border-gray-200 flex pb-[10px] justify-end dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-pgBrand-200">{t("Action")}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role, index) => (
                <tr className="h-14 border-b border-gray-100 border-opacity-10 " key={role.id}>
                  <td className="w-[5%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-pgBrand-200">
                      {index + 1}
                    </p>
                  </td>
                  <td>
                    <p className="text-sm font-bold text-navy-700 dark:text-white">{role.name}</p>
                  </td>
                  <td className="lg:px-8 px-2 flex mt-2 justify-end items-center">
                    <Dropdown
                      button={
                        <button
                          onClick={() => setOpen(!open)} // Toggle action dropdown
                          type="button"
                          className="flex items-center text-xl hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-bold transition duration-200"
                        >
                          <BsThreeDots className="h-6 w-6" />
                        </button>
                      }
                      animation="origin-top-right transition-all duration-300 ease-in-out"
                      classNames="!z-50 top-11 right-0 w-max"
                      children={
                        <div className="!z-50 block w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                          <p
                            onClick={() => fetchRole(role.id)} // Fetch role and open modal
                            className="hover:text-pgBrand-500 flex cursor-pointer items-center gap-2 text-gray-400 hover:font-medium"
                          >
                            <FiEdit2 />
                            {t("Edit")}
                          </p>
                        </div>
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

        {/* Modal for editing role */}
        {editModalOpen && editableRole && (
          <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
            <div className="dark:bg-navy-700 p-6 rounded-lg shadow-lg w-[600px] border border-pgBrand-200">
              <h2 className="text-xl font-bold mb-4">{t("Edit Role")}</h2>

              <label className="font-bold text-white text-2xl">{t("Role Name")}</label>
              <input
                type="text"
                value={editableRole.name}
                onChange={(e) => setEditableRole({ ...editableRole, name: e.target.value })}
                className="bg-navy-700 w-full mb-4 p-2 border border-pgBrand-500 rounded"
              />
              <label className="font-bold text-white text-2xl">{t("Role Description")}</label>
              <textarea
                value={editableRole.description}
                onChange={(e) => setEditableRole({ ...editableRole, description: e.target.value })}
                className="bg-navy-700 w-full mb-4 p-2 border border-pgBrand-500 rounded"
              />

              {/* Permissions - Dynamically render checkboxes */}
              <div className="grid grid-cols-3 gap-4 max-h-60 overflow-y-auto">
                {Object.keys(permissionLabels).map((permission) => (
                  <label className="flex items-center" key={permission}>
                    <input
                      type="checkbox"
                      checked={editableRole.permissions[permission] || false}
                      onChange={() => handleCheckboxChange(permission)}
                      className="mr-2 w-5 h-5 text-pgBrand-500 border-pgBrand-200 focus:ring-pgBrand-500 cursor-pointer" // Ensure size and cursor style
                    />
                    <span className="text-sm">{t(permissionLabels[permission])}</span> {/* Translate and format label */}
                  </label>
                ))}
              </div>

              <div className="flex justify-between w-full !mt-6">
                <button
                  className="flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-red-600 border-2 border-red-600 duration-200 text-sm dark:active:opacity-90 disabled:border-red-600 mr-1 normal-case"
                  onClick={() => setEditModalOpen(false)} // Close modal
                >
                  {t("Cancel")}
                </button>
                <button
                  className="flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-white text-sm bg-pgBrand-500 mr-1 normal-case"
                  onClick={handleUpdateRole} // Trigger role update
                >
                  {t("Submit")}
                </button>
              </div>
            </div>
          </div>
        )}
      <LanguageToggleButton />
    </Card>
  );
};

export default StatusesTable;
