import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = () => {
  const { auth, setAuth } = useAuth();

  const location = useLocation();

  if (auth?.access_token) {
    return <Outlet />;
  } else {
    // Redirect to login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
