import React, { useEffect, useState } from "react"; // Import React, useState, and useEffect
import axios, { axiosPrivate } from "../../../API/axios";
import MiniCalendar from "../../../components/calendar/MiniCalendar";
import WeeklyRevenue from "../../admin/default/components/WeeklyRevenue";
import TotalSpent from "../../admin/default/components/TotalSpent";
import PieChartCard from "../../admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "../../../components/widget/Widget";
import CheckTable from "../default/components/CheckTable";
import ComplexTable from "../default/components/ComplexTable";
import DailyTraffic from "../default/components/DailyTraffic";
import TaskCard from "../default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import { FaUsers } from "react-icons/fa";

const Dashboard = () => {
  const [playgroundsCount, setPlaygroundsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);

  useEffect(() => {
    const fetchPlaygroundsCount = async () => {
      try {
        const response = await axiosPrivate.get('/facility/playgrounds/');
        const data = response.data;
        if (data.status) {
          setPlaygroundsCount(data.data.length);
        } else {
          console.error('Data status is false');
        }
      } catch (error) {
        console.error('Error fetching playgrounds:', error);
      }
    };

    const fetchUsersCount = async () => {
      try {
        const response = await axiosPrivate.get('/manage/users-list/');
        console.log('API Response:', response.data); // Log the response
        const data = response.data;
        if (data.status) {
          setUsersCount(data.data.length); // Update based on actual data structure
        } else {
          console.error('Data status is false');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchPlaygroundsCount();
    fetchUsersCount();
  }, []);

  return (
    <div>
      {/* Card widget */}

      <div className="w-full mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Earnings"}
          subtitle={"3940.5 LYD"}
          custom={true}
        />
        <Widget
          icon={<FaUsers className="h-6 w-6" />}
          title={"Total Users"}
          subtitle={usersCount} // Display user count here
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title="Playgrounds"
          subtitle={playgroundsCount} // Display playground count here
        />
        {/* <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Your Balance"}
          subtitle={"$1,000"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"145"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"$2433"}
        /> */}
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div>

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>

        {/* Complex Table , Task & Calendar */}

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />

        {/* Task chart & Calendar */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
