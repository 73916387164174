import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "@material-tailwind/react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { MdBlockFlipped, MdCancel, MdOutlineCancel } from "react-icons/md";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import { useTranslation } from "react-i18next"; // Import the translation hook

import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import { Form, Formik } from "formik";
import Datepicker from "react-tailwindcss-datepicker";
import { BsClock } from "react-icons/bs";
import AddAvailableHours from "./AddAvailableHours";

function AddPlayground() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [activeStep, setActiveStep] = useState(1);
  const [categories, setCategories] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(); // Initialize translation hook

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFacility, setSelectedFacility] = useState("");

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    status: "",
    category: "",
    open: "",
    closed: "",
    price: "",
    capacity: "",
    facility: "",
    time_slot: "",
    images: [],
  });

  useEffect(() => {
    axiosPrivate.get("/facility/categories/").then((res) => {
      setCategories(res.data.data);
    });
    axiosPrivate.get("/facility/facilities/").then((res) => {
      setFacilities(res.data.data);
    });
    axiosPrivate.get("/facility/status/").then((res) => {
      setStatuses(res.data.data);
    });
  }, [selectedCategory]);

  const handleNext = (e) => {
    e.preventDefault();
    if (activeStep !== 2) {
      !isLastStep && setActiveStep((cur) => cur + 1);
      setIsFirstStep(false);
      setIsLastStep(false);
    } else {
      setIsLastStep(true);
    }
  };
  const handlePrev = (e) => {
    e.preventDefault();
    if (activeStep !== 1) {
      !isFirstStep && setActiveStep((cur) => cur - 1);
      setIsFirstStep(false);
      setIsLastStep(false);
    } else {
      setIsFirstStep(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const createPlayground = (data) => {
    // e.preventDefault();
    setLoading(true);
    //  console.log(formValues);
    var formData = new FormData();
    const fields = [
      "name",
      "phone",
      "status",
      "category",
      "open",
      "closed",
      "price",
      "capacity",
      "facility",
      "time_slot",
      "images",
    ];

    fields.forEach((field) => {
      if (field === "images") {
        for (let i = 0; i < data.images.length; i++) {
          // //  console.log(data.images);
          for (let key of Object.keys(data.images[i])) {
            // //  console.log(key);
            if (key === "image") {
              formData.append(`images[${i}].${key}`, data.images[i][key]);
            } else {
              formData.append(`images[${i}].${key}`, data.images[i][key]);
            }
          }
        }
      } else {
        formData.append(field, formValues[field]);
      }

      // formData.append(field, data.field);
      // //  console.log(field, data.field);
    });

    console.log(data.images);
    for (const [key, value] of formData.entries()) {
      //  console.log(key, value);
    }

    axiosPrivate
      .post("/facility/create-playground/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "New playground has been added successfully!",
          confirmButtonText: "Go To Playgrounds List",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then((response) => {
          navigate("/admin/playgrounds");
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error("error", err);
      
        let errorMessage = "Failed to create the playground. Please try again.";
      
        if (err.response && err.response.data) {
          console.log("Response data:", err.response.data);
      
          const fieldErrors = err.response.data;
          if (fieldErrors) {
            errorMessage = Object.entries(fieldErrors)
              .map(([field]) => {
                return `<strong>${field}</strong>: Missing field`;
              })
              .join('<br><br>');
          }
        }
      
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          html: errorMessage,
          confirmButtonText: "OK",
          confirmButtonColor: "#FF5733",
          background: "#091442",
          color: "white",
        });
      });
      
      
      
  };

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Formik onSubmit={createPlayground} initialValues={formValues}>
        {({ errors, touched, values, isValid, setFieldValue }) => (
          <Form>
            <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
              {/* Stepper */}
              <div className="flex items-center justify-between w-full lg:w-2/3 mx-auto lg:px-10 text-base  text-gray-400 mb-4">
                <div className="flex items-center">
                  <div
                    className={`w-6 h-6
            ${
              activeStep === 1 || activeStep > 1
                ? "bg-secondary-400"
                : "bg-gray-400"
            } rounded-full flex items-center justify-center text-white text-sm `}
                  >
                    1
                  </div>
                  <span
                    className={`ml-4 font-medium text-sm  ${
                      activeStep === 1 || activeStep > 1
                        ? "text-secondary-400"
                        : ""
                    }`}
                  >
                    {t("Information")}
                  </span>
                </div>
                <div
                  className={`h-[2px] w-1/3   
                
                  ${
                    activeStep === 2 || activeStep > 2
                      ? "bg-secondary-400"
                      : "bg-gray-400"
                  }
                  
              `}
                ></div>
                <div className="flex items-center">
                  <div
                    className={`w-6 h-6 
                      ${
                        activeStep === 2 || activeStep > 2
                          ? "bg-secondary-400"
                          : "bg-gray-400"
                      } rounded-full flex items-center justify-center text-white text-sm `}
                  >
                    2
                  </div>
                  <span
                    className={`ml-4 font-medium text-sm  ${
                      activeStep === 2 || activeStep > 2
                        ? "text-secondary-400"
                        : ""
                    }`}
                  >
                    {t("Images")}
                  </span>
                </div>
              </div>
              {activeStep === 1 ? (
                <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden py-2">
                  <div className="">
                    <h2 className="capitalize w-full ">{t("name")}</h2>
                  </div>
                  {/* <div className="w-full grid grid-cols-1  gap-4 mt-2">
                    <div className="relative">
                      <input
                        type="text"
                        required
                        id="name"
                        name="name"
                        onChange={handleChange}
                        className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=""
                      />
                      <label
                        htmlFor="name"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Playground Name
                      </label>
                    </div>
                  </div> */}
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div className="relative">
                      <input
                        type="text"
                        required
                        defaultValue={formValues.name}
                        id="name"
                        name="name"
                        onChange={handleChange}
                        className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=""
                      />
                      <label
                        htmlFor="name"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Playground Name")}
                      </label>
                    </div>
                    <div className="relative ">
                      <input
                        type="text"
                        defaultValue={formValues.phone}
                        id="phone"
                        required
                        onChange={handleChange}
                        name="phone"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="phone"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Phone Number")}
                      </label>
                    </div>
                  </div>

                  <div className=""></div>
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                    <div>
                      <h2 className="capitalize w-full mt-6 mb-4">{t("Category")} </h2>
                      <div className="relative ">
                        <select
                          id="category"
                          name="category"
                          defaultValue={formValues.category}
                          required
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedCategory(
                              e.target.selectedOptions[0].innerHTML
                            );
                          }}
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                          rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:bg-navy-800
                        dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                          focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                          placeholder=""
                        >
                          <option defaultValue disabled>
                            {t("Select A Category")}
                          </option>
                          {categories
                            // .filter((category) => !selectedCategory.includes(category.name))
                            .map((category) => {
                              return (
                                <option value={category.id} key={category.id}>
                                  {/* <SVG
                            src={category.icon}
                            alt="My SVG"
                            preProcessor={(code) =>
                              code.replace(/fill=".*?"/g, 'fill="currentColor"')
                            }
                            className="w-5 h-5"
                          ></SVG> */}
                                  {category.name}
                                </option>
                              );
                            })}
                        </select>
                        <label
                          htmlFor="category"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                          transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                          peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                          peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                          peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                          rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          {t("Category")}
                        </label>
                        <div className="flex space-x-2 mt-3">
                          {selectedCategory ? (
                            <span
                              className="flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                          drop-shadow-sm bg-opacity-10 px-2 py-1
                         text-xs font-medium  ring-1 ring-inset ring-pgBrand-200"
                            >
                              <div
                                className={`rounded-full text-lg cursor-pointer`}
                                onClick={() => {
                                  setSelectedCategory("");
                                }}
                              >
                                <MdOutlineCancel className="text-gray-50" />
                              </div>
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {selectedCategory}
                              </p>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="capitalize w-full mt-6 mb-4">{t("Facility")} </h2>
                      <div className="relative ">
                        <select
                          id="facility"
                          defaultValue={formValues?.facility}
                          name="facility"
                          required
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedFacility(
                              e.target.selectedOptions[0].innerHTML
                            );
                          }}
                          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:bg-navy-800
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                          placeholder=""
                        >
                          <option defaultValue disabled>
                            Select A Facility
                          </option>
                          {facilities.map((facility) => {
                            return (
                              <option value={facility.id} key={facility.id}>
                                {facility.name}
                              </option>
                            );
                          })}
                        </select>
                        <label
                          htmlFor="category"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Category
                        </label>
                        <div className="flex space-x-2 mt-3">
                          {selectedFacility ? (
                            <span
                              className="flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                          drop-shadow-sm bg-opacity-10 px-2 py-1
                         text-xs font-medium  ring-1 ring-inset ring-pgBrand-200"
                            >
                              <div
                                className={`rounded-full text-lg cursor-pointer`}
                                onClick={() => {
                                  setSelectedFacility("");
                                }}
                              >
                                <MdOutlineCancel className="text-gray-50" />
                              </div>
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {selectedFacility}
                              </p>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <h2 className="capitalize w-full mt-8">{t("Working Hours")}</h2>
                  </div>

                  <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                    <div className="relative">
                      <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="rgb(0 208 231)"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="time"
                        id="open"
                        defaultValue={formValues.open}
                        name="open"
                        onChange={handleChange}
                        className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer
                  "
                        required
                      />
                      <label
                        htmlFor="open"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Open")}
                      </label>
                    </div>

                    <div className="relative">
                      <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="rgb(0 208 231)"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="time"
                        defaultValue={formValues.closed}
                        id="closed"
                        onChange={handleChange}
                        name="closed"
                        className="block px-2.5 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent 
                  rounded-lg border border-pgBrand-200 appearance-none dark:text-white
                dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                  focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer
                  "
                        required
                      />
                      <label
                        htmlFor="closed"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Close")}
                      </label>
                    </div>
                    <div className="relative">
                      <select
                        id="status"
                        name="status"
                        defaultValue="" // Start with no selection
                        onChange={handleChange}
                        required
                        className="block px-2.5 pb-3.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                          rounded-lg border border-pgBrand-200 dark:text-white dark:bg-navy-800
                          dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                          focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                      >
                        <option value="" disabled>
                          {t("Select The Current Status")}
                        </option>
                        {statuses.map((status) => (
                          <option value={status.id} key={status.id}>
                            {status.name}
                          </option>
                        ))}
                      </select>
                      <label
                        htmlFor="status"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                          transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                          peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                          peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                          peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                          rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Current Status")}
                      </label>
                    </div>

                    <div className="relative ">
                      <input
                        type="number"
                        id="capacity"
                        defaultValue={formValues.capacity}
                        required
                        onChange={handleChange}
                        name="capacity"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="capacity"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Capacity")}
                      </label>
                    </div>
                    <div className="relative">
                      <select
                        id="time_slot"
                        required
                        defaultValue="" // Start with no selection
                        onChange={handleChange}
                        name="time_slot"
                        className="block px-2.5 pb-3.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                          rounded-lg border border-pgBrand-200 dark:text-white dark:bg-navy-800
                          dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                          focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                      >
                        <option value="" disabled>
                          {t("Select Time Slot")}
                        </option>
                        <option value="10">{t("10 دقائق")}</option>
                        <option value="15">{t("15 دقيقة")}</option>
                        <option value="20">{t("20 دقيقة")}</option>
                        <option value="25">{t("25 دقيقة")}</option>
                        <option value="30">{t("30 دقيقة")}</option>
                        <option value="35">{t("35 دقيقة")}</option>
                        <option value="40">{t("40 دقيقة")}</option>
                        <option value="45">{t("45 دقيقة")}</option>
                        <option value="50">{t("50 دقيقة")}</option>
                        <option value="55">{t("55 دقيقة")}</option>
                        <option value="60">{t("60 دقيقة")}</option>
                      </select>
                      <label
                        htmlFor="time_slot"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                          transform -translate-y-4 scale-75 top-2 z-10 origin-[0] !bg-navy-800 px-2
                          peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                          peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                          peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                          rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Time Slot")}
                      </label>
                    </div>

                    <div className="relative ">
                      <input
                        type="number"
                        id="price"
                        defaultValue={formValues.price}
                        required
                        onChange={handleChange}
                        name="price"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                        placeholder=" "
                      />
                      <label
                        htmlFor="price"
                        className="tracking-wider absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        {t("Price Per Time Slot")}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-4 h-full overflow-x-scroll xl:overflow-hidden py-2">
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-96 border-2 border-gray-300 border-dashed rounded-lg 
                      cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100
                       dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 bg-cover
                      "
                      style={{ backgroundImage: `url(${selectedImage})` }}
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          PNG Or JPG
                        </p>
                      </div>

                      <input
                        id="dropzone-file"
                        type="file"
                        name="images[0].image"
                        className="hidden"
                        onChange={(event) => {
                          handleFileChange(event);

                          setFieldValue(
                            `images[0].image`,
                            event.currentTarget.files[0]
                          );
                          setFieldValue(`images[0].is_more`, false);
                          setFieldValue(`images[0].is_cover_image`, true);
                          setFieldValue(`images[0].is_profile_image`, false);
                        }}
                        accept=".png, .jpg, .jpeg"
                        multiple={false}
                      />
                    </label>
                  </div>
                </div>
              )}
              <div className="flex justify-between w-full  !mt-20">
                <Button
                className="flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-red-600 border-2 border-red-600 duration-200 text-sm dark:active:opacity-90 disabled:border-red-600 mr-1"
                  onClick={() => navigate(-1)}
                  disabled={isFirstStep}
                  variant="outlined"
                  color="red"
                  type="button"
                >
                  <span>{t("Cancel")}</span>
                </Button>
                {activeStep === 2 ? (
                  <Button
                    loading={loading}
                    disabled={loading}
                    className="linear  flex  rounded-[20px] bg-pgBrand-500 px-6 py-2
                      font-medium text-white  
                     duration-200 w-fit space-x-4 hover:bg-pgBrand-550 active:bg-pgBrand-550
                      text-sm dark:bg-pgBrand-500 
                  dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
                    type="submit"
                  >
                    <span>{t("Submit")}</span>
                  </Button>
                ) : (
                  <Button
                    className="linear w-fit  flex space-x-4 rounded-[20px]  
                  px-6 py-2  font-medium text-pgBrand-550 duration-200  hover:bg-pgBrand-550
                    text-sm   border-2 border-pgBrand-500 hover:text-white
                   dark:active:opacity-90 disabled:border-pgBrand-500"
                    onClick={handleNext}
                    disabled={isLastStep || !isValid}
                    type="button"
                  >
                    <span>{t("Next")}</span>
                    <IoChevronForward className="h-5 w-5 " />
                  </Button>
                )}
              </div>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddPlayground;
