import { useState, useEffect } from 'react';
import AccountSettings from "./components/AccountSettings";
import SocialProfiles from "./components/SocialProfiles";
import ChangePassword from "./components/ChangePassword";
import { axiosPrivate } from "../../../API/axios";

const ProfileOverview = () => {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});

  useEffect(() => {
    axiosPrivate.get("/auth/current_user/")
      .then((res) => {
        const userData = res.data.data;
        setUser(userData);

        return axiosPrivate.get(`/manage/${userData.id}/view-user/`);
      })
      .then((profileRes) => {
        const profileData = profileRes.data.data.profile;
        setProfile(profileData);
        setUser((prevUser) => ({
          ...prevUser,
          phone: profileData.phone,
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-8 h-max">
        <AccountSettings user={user} profile={profile} setUser={setUser} />
        <div className="space-y-6">
{/*           <SocialProfiles />*/}
        <ChangePassword />
        </div>
      </div>
    </div>
  );
};

export default ProfileOverview;