import Card from "../card";

const Widget = ({ icon, title, subtitle, custom }) => {
  return (
    <Card
      extra={`!flex-row  flex-grow items-center rounded-[20px] ${
        custom ? "dark:!bg-pgBrand-500" : ""
      }`}
    >
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div
          className={`rounded-full bg-lightPrimary p-3  ${
            custom ? "dark:bg-pgBrand-550" : "dark:bg-navy-700"
          }`}
        >
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p
          className={`font-dm text-sm font-medium ${
            custom ? "dark:text-pgBrand-300" : "dark:text-gray-600"
          }`}
        >
          {title}
        </p>
        <h4
          className={`text-xl font-bold text-navy-700 ${
            custom ? "dark:text-pgBrand-300" : "dark:text-white"
          }`}
        >
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
