
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "./variables/columnsData";

import Card from "../../../components/card";


import StatusesTable from "./components/StatusesTable";

const Management = () => {

  return (
    <div>
      <Card exstra={"w-full"}></Card>
      <div className="mt-5 grid  grid-cols-1 gap-5 md:grid-cols-1 ">
        {/* <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
      </div>

      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
        <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        /> */}

        <StatusesTable columnsData={columnsDataComplex} />
      </div>
    </div>
  );
};

export default Management;
