import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../assets/img/svg2.svg";
import axios from "../../API/axios";
import useAuth from "../../hooks/useAuth";

function LoginPage() {
  const navigate = useNavigate();
  const { setAuth, persist, setPersist } = useAuth();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (credentials.email === "" || credentials.password === "")
      return setError({ error: true, message: "All fields are required" });
    else {
      axios
      .post("auth/login/", credentials)
      .then((res) => {
        const access_token = res.data.data.access_token;
        const refresh_token = res.data.data.refresh_token;
        const user = res.data.data.user;

        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("user", JSON.stringify(user));

        setAuth({ user, access_token, refresh_token });

        // Check for 'has_dashboard_permission' in the user object
        if (user.has_dashboard_permission) {
          // If true, redirect to admin/default
          navigate("/admin/default", { replace: true });
        } else {
          // If false, redirect to admin/playgrounds
          navigate("/admin/playgrounds", { replace: true });
        }
        
        window.location.reload();               
      })
      .catch((err) => {
        setError({
          error: true,
          message: err.response.data.message,
        });
        setLoading(false);
      });    
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin(e); // Manually trigger form submission
    }
  };
  const togglePersist = () => {
    setPersist((persist) => !persist);
  };

  useEffect(() => {
    localStorage.setItem("persist", true);
  }, [persist]);

  return (
    <>
      <div
        className="flex z-10 h-screen w-full items-center absolute
     justify-center bg-cover bg-no-repeat  
       "
        style={{
          backgroundImage: `url(${Image})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="flex z-20 h-screen w-full items-center relative
     justify-center"
      >
        <div
          className="flex items-center  relative z-20 md:rounded-3xl shadow-2xl h-screen  md:h-fit w-full  
         px-16 py-20 md:mx-auto md:max-w-lg  backdrop-blur-md border border-pgBrand-300 bg-pgBrand-400 bg-opacity-45
          border-secondary-100 border-opacity-50"
        >
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl  text-center text-white">Mubarti</h2>

              <p className="mt-2  text-slate-400 ">
                Log in to access your account
              </p>
            </div>

            <div className="mt-8">
              <form onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-xs text-white  tracking-widest"
                  >
                    email
                  </label>
                  <input
                    type="email"
                    onChange={handleChange}
                    name="email"
                    id="email"
                    autoFocus="on"
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                    placeholder="Email Address"
                    className={`block w-full text-sm px-4 py-2 mt-2 
                 placeholder-gray-400 bg-white 
                 rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                  focus:border-pgBrand-500  focus:ring-pgBrand-500 text-white  focus:outline-pgBrand-500 focus:ring focus:ring-opacity-60
                  ${error.error ? "ring ring-rose-600  border-error" : ""}`}
                  />
                </div>

                <div className="mt-6">
                  <div className="flex justify-between mb-2">
                    <label
                      htmlFor="password"
                      className="text-xs text-white  tracking-widest"
                    >
                      Password
                    </label>
                    <button
                      onClick={() => navigate("/ForgotPassword")}
                      className="text-xs text-gray-400 tracking-widest hover:text-pgBrand-200 "
                    >
                      Forgot password?
                    </button>
                  </div>

                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    id="password"
                    onKeyDown={handleKeyDown}
                    placeholder="Your Password"
                    className={`block w-full text-sm px-4 py-2 mt-2 
                    placeholder-gray-400 bg-white 
                    rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                     focus:border-pgBrand-500  focus:ring-pgBrand-500 text-white  focus:outline-pgBrand-500 focus:ring focus:ring-opacity-60
                     ${error.error ? "ring ring-rose-600 border-error" : ""}`}
                  />
                </div>

                {error.error ? (
                  <div>
                    <p className="text-xs font-semibold tracking-wider text-rose-500 mt-4">
                      {error.message}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                <div className="items-center mt-3 flex">
                  <input
                    type="checkbox"
                    id="persist"
                    onChange={togglePersist}
                    checked={persist}
                    className="h-3 w-3 cursor-base focus:ring-blue rounded border-gray-300"
                    onKeyDown={handleKeyDown}
                  />
                  <label
                    htmlFor="persist "
                    className="text-xs text-gray-600 ml-2"
                  >
                    Remember Me
                  </label>
                </div>
                <div className="mt-10">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full px-4 py-2 tracking-wide text-white font-medium shadow-sm transition-colors duration-200
                 transform bg-pgBrand-500 rounded-md hover:bg-secondary-100 focus:outline-none
                 disabled:bg-gray-400 focus:bg-pgBrand-500 focus:ring focus:ring-secondary-300 focus:ring-opacity-60"
                  >
                    Log in
                  </button>
                </div>
              </form>

              <p className="mt-6 text-sm text-center  text-slate-400">
                Don&#x27;t have an account yet? &nbsp;
                <a
                  href="#"
                  className="text-pgBrand-200 focus:outline-none focus:underline hover:text-secondary-100"
                >
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
