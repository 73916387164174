import React from "react";
import Card from "../../../../components/card";

const SocialProfiles = () => {
  return (
    <Card extra={"p-6"}>
      <h2 className="text-xl font-semibold mb-4">Social Profiles</h2>
      <form className="space-y-6">
        <div className="relative">
          <input
            id="facebook_username"
            type="text"
            placeholder=""
            disabled
            className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
            rounded-lg border border-pgBrand-200 appearance-none dark:text-white
            dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
            focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
          />
          <label
            htmlFor="facebook_username"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
            transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
            peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Facebook Username
          </label>
        </div>
        <div className="relative">
          <input
            id="twitter_username"
            type="text"
            placeholder=""
            disabled
            className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
            rounded-lg border border-pgBrand-200 appearance-none dark:text-white
            dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
            focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
          />
          <label
            htmlFor="twitter_username"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
            transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
            peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Twitter Username
          </label>
        </div>
        <div className="relative">
          <input
            id="ig_username"
            type="text"
            placeholder=""
            disabled
            className="block px-2.5 pb-2 pt-3 w-full  text-sm text-gray-900 bg-transparent 
            rounded-lg border border-pgBrand-200 appearance-none dark:text-white
            dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
            focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
          />
          <label
            htmlFor="ig_username"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
            transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-800 px-2
            peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
            peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
            peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
            rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Instagram Username
          </label>
        </div>
        <div className="flex justify-end mt-10">
          <button
            type="submit"
            className="align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none linear rounded-[20px] bg-pgBrand-500 px-12 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
          >
            Save changes
          </button>
        </div>
      </form>
    </Card>
  );
};

export default SocialProfiles;
