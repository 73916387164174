import React from "react";
import Image from "../../assets/img/Mubarti-VI.svg";
import { useNavigate } from "react-router-dom";

function Unauthorized() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex z-10 h-screen w-full bg-primary-300 items-center absolute
justify-center bg-cover bg-no-repeat  
 "
        style={{
          backgroundImage: `url(${Image})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div
        class="h-screen w-full flex flex-col justify-center items-center 
       z-20  relative
     "
      >
        <h1 class="text-9xl lg:text-[15rem] font-extrabold text-white tracking-widest">
          401
        </h1>
        <div class="bg-pgBrand-500 tracking-wider mb-6 px-2 lg:mb-0 lg:px-5 py-[2px] text-xs lg:text-xl
         lg:-ml-4 rounded -rotate-[18deg] absolute">
          Not Authorized
        </div>
        <button
          onClick={() => navigate("/login")}
          type="submit"
          className="px-16  mt-8 py-2 tracking-wide  text-xs lg:text-base text-pgBrand-300 font-medium shadow-sm transition-colors duration-200
                 transform bg-pgBrand-500 rounded-xl hover:bg-secondary-100 focus:outline-none
                 disabled:bg-gray-400 focus:bg-pgBrand-500 focus:ring focus:ring-secondary-300 focus:ring-opacity-60"
        >
          Back to Login
        </button>
      </div>
    </>
  );
}

export default Unauthorized;
