import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ForgotPassword from "./views/auth/ForgotPassword";
import Layout from "./views/auth/Layout";
import PersistLogin from "./views/auth/PersistLogin";
import RequireAuth from "./views/auth/RequireAuth";
import AuthLayout from "./layouts/auth/index1";
import AdminLayout from "./layouts/admin";
import RtlLayout from "./layouts/rtl";
import LoginPage from "./views/auth/LoginPage";
import ResetPassword from "./views/auth/ResetPassword";
import CompleteRegistration from "./views/auth/CompleteRegistration";
import Unauthorized from "./views/auth/Unauthorized";
import { useState, useEffect } from "react";
import i18n from 'i18next';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';
import { FaGlobe } from "react-icons/fa";

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ar: { translation: arTranslation },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export function LanguageToggleButton() {
  const { i18n, t } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLang);
    
    // Dynamically change direction on language switch
/*     document.documentElement.dir = newLang === 'ar' ? 'rtl' : 'ltr';
 */  };

  return (
    <div className="mt-10">
      {/* Header with Language Toggle Button */}
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">Language</div>
        <button
          onClick={toggleLanguage}
          className="linear group w-fit rounded-[20px] flex items-center space-x-3 bg-pgBrand-500 px-8 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 dark:hover:bg-pgBrand-550 dark:active:opacity-90"
        >
          <FaGlobe className="group-hover:rotate-90 transition-transform duration-300" />
          <span className="uppercase tracking-wider text-xs">{t('toggle_language')}</span>
        </button>
      </div>
    </div>
  );
}

function App() {
  const [userPermissions, setUserPermissions] = useState({
    has_user_permission: false,
    has_survey_permission: false,
    has_settings_permission: false,
    has_activity_permission: false,
    has_dashboard_permission: false,
  });

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
  
    if (storedUser) {
      const permissions = {
        has_user_permission: storedUser.has_user_permission,
        has_survey_permission: storedUser.has_survey_permission,
        has_settings_permission: storedUser.has_settings_permission,
        has_activity_permission: storedUser.has_activity_permission,
        has_dashboard_permission: storedUser.has_dashboard_permission,
      };
      setUserPermissions(permissions);
    }
  }, []);

  useEffect(() => {
    // Set the initial direction based on the default language (en or ar)
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="ResetPassword" element={<ResetPassword />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="web/manage/complete-registration/:id" element={<CompleteRegistration />} />

          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              {/* Dynamically switch the layout based on the current language */}
              <Route
                path="admin/*"
                element={
                  i18n.language === 'ar' ? (
                    <RtlLayout key={i18n.language} userPermissions={userPermissions} />
                  ) : (
                    <AdminLayout key={i18n.language} userPermissions={userPermissions} />
                  )
                }
              />
              <Route path="/" element={<Navigate to="/admin" replace />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </I18nextProvider>
  );
}

export default App;
