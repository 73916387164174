import React, { useState, useEffect } from "react";
import { FaPlus, FaTrashAlt } from "react-icons/fa";  // Import the trash icon
import { IoChevronForward } from "react-icons/io5";
import { BsQuestionCircle } from 'react-icons/bs';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Button } from "@material-tailwind/react";
import Card from "../../../../components/card";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the translation hook

const CreateSurvey = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translation hook
  const [loading, setLoading] = useState(false);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [survey, setSurvey] = useState({
    name: "",
    description: "",
    editable: false,
    deletable: false,
    duplicate_entry: false,
    private_response: false,
    can_anonymous_user: false,
    notification_to: "",
  });

  useEffect(() => {
    axiosPrivate
      .get("/survey/question-type-list/")
      .then((res) => {
        setQuestionTypes(res.data.data);
      })
      .catch((err) => console.error(err));
  }, [axiosPrivate]);

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        key: `question_${questions.length + 1}`,
        label: "",
        type_field: 0,
        choices: "", // Initialize choices
        help_text: "",
        required: false,
        ordering: questions.length + 1,
      },
    ]);
  };

  const handleSurveyChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setSurvey({ ...survey, [name]: inputValue });
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = questions.map((question, idx) =>
      idx === index ? { ...question, [field]: value } : question
    );
    setQuestions(updatedQuestions);
  };

  const removeQuestion = (index) => {
    Swal.fire({
      title: "Are you sure you want to remove this question?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: "Yes, remove it!",
      background: "#091442",
      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the question from the state
        const updatedQuestions = questions.filter((_, idx) => idx !== index);
        setQuestions(updatedQuestions);

        Swal.fire({
          icon: "success",
          title: "Question removed!",
          background: "#091442",
          iconColor: "#F559FB",
          color: "white",
        });
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const surveyData = {
      ...survey,
      questions,
    };

    try {
      await axiosPrivate.post("/survey/create-survey/", surveyData);
      Swal.fire({
        icon: "success",
        title: "Survey Created Successfully!",
        confirmButtonText: "Go to Surveys",
      }).then(() => navigate("/admin/surveys"));
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue creating the survey. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      <Card extra={"w-full h-full p-6 sm:overflow-x-auto"}>
        <form onSubmit={handleSubmit}>
          <h1 className="text-2xl font-bold mb-4">{t("Create a New Survey")}</h1>

          {/* Survey Details */}
          <div className="mb-6">
            <div className="mb-4">
              <label className="block text-xl font-bold dark:text-white">{t("Survey Name")}</label>
              <input
                type="text"
                name="name"
                className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                value={survey.name}
                onChange={handleSurveyChange}
                placeholder="Survey Name"
              />
            </div>

            <div className="mb-4">
              <label className="block text-xl font-bold dark:text-white">{t("Description")}</label>
              <textarea
                name="description"
                className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                value={survey.description}
                onChange={handleSurveyChange}
                placeholder="Survey Description"
              />
            </div>

            <div className="mb-4">
              <label className="block text-xl font-bold dark:text-white">{t("Notification Email")}</label>
              <input
                type="email"
                name="notification_to"
                className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                value={survey.notification_to}
                onChange={handleSurveyChange}
                placeholder="Notification Email"
              />
            </div>

            {/* Admin-Controllable Checkboxes */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <label className="dark:text-white flex items-center text-lg font-medium">
                <input
                  type="checkbox"
                  name="editable"
                  checked={survey.editable}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                {t("Editable")}
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows the user to edit their answer after submission."
                />
              </label>

              <label className="dark:text-white flex items-center text-lg font-medium">
                <input
                  type="checkbox"
                  name="deletable"
                  checked={survey.deletable}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                {t("Deletable")}
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows the user to delete their answer after submission."
                />
              </label>

              <label className="dark:text-white flex items-center text-lg font-medium">
                <input
                  type="checkbox"
                  name="duplicate_entry"
                  checked={survey.duplicate_entry}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                {t("Allow Duplicate Entries")}
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows users to submit multiple responses."
                />
              </label>

              <label className="dark:text-white flex items-center text-lg font-medium">
                <input
                  type="checkbox"
                  name="private_response"
                  checked={survey.private_response}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                {t("Private Response")}
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Hides the response from other users."
                />
              </label>

              <label className="dark:text-white flex items-center text-lg font-medium">
                <input
                  type="checkbox"
                  name="can_anonymous_user"
                  checked={survey.can_anonymous_user}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                {t("Allow Anonymous User")}
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Allows users to submit responses without logging in."
                />
              </label>
              <label className="dark:text-white flex items-center text-lg font-medium">
                <input
                  type="checkbox"
                  name="in_use"
                  checked={survey.in_use}
                  onChange={handleSurveyChange}
                  className="mr-2"
                />
                {t("In Use?")}
                <BsQuestionCircle
                  className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                  title="Enables this survey as the active one"
                />
              </label>
            </div>
          </div>


          {/* Questions Section */}
          <div className="mb-6">
            <h2 className="text-2xl font-bold mb-4 dark:text-white">{t("Add Your Questions")}</h2>
              {questions.map((question, index) => (
              <div key={index} className="mb-4">
                {/* Question Key Input */}
                <div className="mb-2">
                  <label className="block text-xl font-bold text-gray-700 dark:text-white">{t("Question Key")}</label>
                  <input
                    type="text"
                    className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                    value={question.key}
                    onChange={(e) => handleQuestionChange(index, "key", e.target.value)}
                    placeholder="Enter the question key"
                  />
                </div>

                {/* Question Label */}
                <div className="mb-2">
                  <label className="block text-xl font-bold text-gray-700 dark:text-white">{t("Question")}</label>
                  <input
                    type="text"
                    className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                    value={question.label}
                    onChange={(e) => handleQuestionChange(index, "label", e.target.value)}
                    placeholder="Enter the question"
                  />
                </div>

                {/* Help Text */}
                <div className="mb-2">
                  <label className="text-xl font-bold text-gray-700 dark:text-white flex items-center">
                    {t("Help Text")}
                    <BsQuestionCircle
                      className="ml-2 w-4 h-4 text-gray-400 cursor-pointer"
                      title="Filling this help text will display a small tooltip icon next to the question, providing users with additional guidance or clarification. Exactly Like This One!"
                    />
                  </label>
                  <input
                    type="text"
                    className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                    value={question.help_text}
                    onChange={(e) => handleQuestionChange(index, "help_text", e.target.value)}
                    placeholder="Help text (optional)"
                  />
                </div>


                {/* Question Type */}
                <div className="relative mb-2">
                  <label className="block text-xl font-bold text-gray-900 dark:text-white">{t("Question Type")}</label>
                  <select
                    value={question.type_field}
                    onChange={(e) => handleQuestionChange(index, "type_field", parseInt(e.target.value))}
                    className="relative text-lg text-gray-900 px-2.5 pb-2.5 pt-2.5 w-full cursor-default bg-transparent rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:border-pgBrand-200 focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                  >
                    {questionTypes.map((type) => (
                      <option
                        key={type.id}
                        value={type.id}
                        className="bg-navy-800 text-lg text-gray-900 dark:text-white hover:bg-pgBrand-500 hover:text-white cursor-pointer"
                      >
                        {type.type}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Choices Input (conditional rendering) */}
                {(parseInt(question.type_field) === 3 || parseInt(question.type_field) === 4) && (
                  <div className="mb-2">
                    <label className="block text-xl font-bold text-gray-700 dark:text-white">{t("Choices (comma-separated)")}</label>
                    <input
                      type="text"
                      className="block w-full px-2.5 pb-2.5 pt-4 text-lg dark:text-white bg-transparent rounded-lg border border-pgBrand-200 focus:ring-1 focus:border-pgBrand-200 placeholder-gray-500"
                      value={question.choices}
                      onChange={(e) => handleQuestionChange(index, "choices", e.target.value)}
                      placeholder="Enter choices (e.g., Choice A, Choice B, Choice C)"
                    />
                  </div>
                )}

                {/* Required Checkbox */}
                <div className="mb-2">
                  <label className="text-xl font-bold text-gray-700 dark:text-white flex items-center">
                    <input
                      type="checkbox"
                      checked={question.required}
                      onChange={(e) => handleQuestionChange(index, "required", e.target.checked)}
                      className="mr-2"
                    />
                    {t("Required?")}
                  </label>
                  
                </div>

                {/* Delete Button */}
                <button
                  type="button"
                  onClick={() => removeQuestion(index)}
                  className="flex items-center space-x-2 text-red-600"
                >
                  <FaTrashAlt />
                  <span>{t("Delete Question")}</span>
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addQuestion}
              className="flex items-center space-x-2 p-2 bg-pgBrand-250 text-white rounded-lg"
            >
              <FaPlus />
              <span>{t("Add Question")}</span>
            </button>
          </div>


          {/* Submit Survey */}
          <div className="flex justify-between w-full !mt-6">
          <Button
            variant="outlined"
            color="red"
            type="button"
            onClick={() => navigate(-1)}
            className="normal-case flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-red-600 border-2 border-red-600 duration-200 text-sm dark:active:opacity-90 disabled:border-red-600 mr-1">
            <span>{t("Cancel")}</span>
          </Button>
          <Button
              type="submit"
              disabled={loading}
              className="normal-case flex items-center rounded-[20px] bg-pgBrand-500 px-6 py-2 font-medium text-white duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 disabled:bg-gray-400"
            >
              {loading ? "Creating..." : "Submit"}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default CreateSurvey;
