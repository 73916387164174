import Card from "../../../../components/card";
import {
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import {
  MdOutlineError,
  MdBlockFlipped,
  MdOutlineCancel,
} from "react-icons/md";
import { LuUserX2 } from "react-icons/lu";
import { IoInformationCircleOutline } from "react-icons/io5";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2"; // Ensure you import necessary icons
import { FaRegCircleCheck } from "react-icons/fa6";
import { HiX } from "react-icons/hi";

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../../components/dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FiArchive } from "react-icons/fi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next"; // Import the translation hook
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  select,
} from "@material-tailwind/react";
import placeholder from "../../../../assets/img/profile/userPlaceholder2.png";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react"; // Import useRef and useEffect for detecting outside clicks

const UsersTable = (props) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const { columnsData, tableData, getUsers, uniqueStatuses, selectedStatus, setSelectedStatus, toggleActionDropdown, actionDropdownOpen, hasPermission } = props;
  const { transparent } = false;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(); // Initialize translation hook

  const [userData, setUserData] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [userInterests, setUserInterests] = useState([]);
  const [favoriteFacilities, setFavoriteFacilities] = useState([]);
  const [favoritePlaygrounds, setFavoritePlaygrounds] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [modalEditable, setModalEditable] = useState(false);
  const [statusQuery, setStatusQuery] = useState(null);
  const dropdownRef = useRef(null); // Create a ref for the dropdown
  const [roles, setRoles] = useState([]);
  const [hasEditPermission, setHasEditPermission] = useState(false); // New state for permission

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const fetchRoles = () => {
    axiosPrivate.get("/manage/roles/").then((res) => {
      setRoles(res.data.data);
    }).catch(error => {
      console.error('Error fetching roles:', error);
    });
  };

  useEffect(() => {
    fetchRoles();
  }, []);
  

  const fetchUserInterests = async () => {
    try {
      const response = await axiosPrivate.get(`/manage/${userData.id}/user-interest/`);
      if (response.data.status) {
        setUserInterests(response.data.data.categories);
      }
    } catch (error) {
      console.error('Error fetching user interests:', error);
    }
  };
  
  const fetchFavoriteFacilities = async () => {
    try {
      const response = await axiosPrivate.get(`/facility/${userData.id}/favorites-facilities/`);
      if (response.data.status) {
        const facilitiesWithUserId = response.data.data.map(facility => ({
          ...facility,
          userId: userData.id,
        }));
        setFavoriteFacilities(facilitiesWithUserId);
      }
    } catch (error) {
      console.error('Error fetching favorite facilities:', error);
    }
  };
  
  const fetchFavoritePlaygrounds = async () => {
    try {
      const response = await axiosPrivate.get(`/facility/${userData.id}/favorites-playgrounds/`);
      if (response.data.status) {
        const playgroundsWithUserId = response.data.data.map(playground => ({
          ...playground,
          userId: userData.id,
        }));
        setFavoritePlaygrounds(playgroundsWithUserId);
      }
    } catch (error) {
      console.error('Error fetching favorite playgrounds:', error);
    }
  };
  
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // Update selected status
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
    setModalEditable(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
/*     getTableBodyProps,
    headerGroups,
    page,
    prepareRow, */
    initialState,
  } = tableInstance;
  initialState.pageSize = 2;

  const archiveUser = (email) => {
    const data = { email: email };
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: "Archive User!",
      background: "#091442",
      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate
          .post("/manage/archived-user/", data)
          .then((res) => {
            //  console.log(res);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              confirmButtonText: "Go Back",
              confirmButtonColor: "#45acc4",
            });
          });
      }
    });
  };

  const banUser = (id, statusId) => {
    // Check if the user is currently banned (statusId === 4)
    const isBanned = statusId === 4;
    
    // Update confirmation and success messages based on the status
    const action = isBanned ? "Unban" : "Ban";
    const successMessage = isBanned ? "User Unbanned successfully!" : "User Banned successfully!";
  
    Swal.fire({
      title: `Are you sure you want to ${action} this user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: `${action} User!`,
      background: "#091442",
      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate
          .post(`/manage/${id}/ban-unban-user/`, {})
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: successMessage,
              confirmButtonText: "Ok",
              confirmButtonColor: "#34EDFF",
              background: "#091442",
              color: "white",
            }).then((response) => {
              getUsers(); // Refresh the users list after action
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              confirmButtonText: "Go Back",
              confirmButtonColor: "#45acc4",
            });
          });
      }
    });
  };
  

  const getUser = (id) => {
    axiosPrivate
      .get(`/manage/${id}/view-user/`)
      .then((res) => {
        // Set user data and profile details from the response
        setUserData(res.data.data);
        setUserProfile(res.data.data.profile);
        setUserRoles(res.data.data.profile.roles);
        setProfileImage(res.data.data.profile.image);
        setHasEditPermission(res.data.has_permission);
      })
      .then(() => handleModalOpen())
      .catch((error) => {
        // Log the error details
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
      });
  };

  const updateUser = () => {
    setLoading(true);
    const formData = updatedUserData;
    formData.role_ids = userRoles.map((role) => {
      return role.id;
    });
    //  console.log(formData);
    axiosPrivate
      .patch(`/manage/user/${userData.id}/update/`, formData)
      .then((res) => {
        setLoading(false);
        handleModalOpen();
        //  console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "User information has been updated successfully.",
          confirmButtonText: "Ok",
          confirmButtonColor: "#34EDFF",
          background: "#091442",
          color: "white",
        }).then((response) => {
          getUsers();
        });
      })
      .catch((err) => {
        console.error('Response:', err.response); // This will give you the full response details
        console.error(err);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: err.response.data.message,
          confirmButtonText: "Go Back",
          confirmButtonColor: "#45acc4",
        });
      });


  };
  return (
    <Card
      extra={
        "w-full h-full p-4 sm:overflow-x-auto bg-!pgBrand-300 mb-10 overflow-y-visible"
      }
    >
    <div className="relative flex items-center justify-between">
      <div className="text-xl font-bold text-navy-700 dark:text-white">
        Total Users
      </div>

      <div className="flex items-center space-x-4"> {/* Flex container for select and button */}
        {/* Selected Status Display */}
        {statusQuery && (
          <span
            className={`flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl drop-shadow-sm bg-opacity-10 px-2 py-1 text-xs font-medium ring-1 ring-inset bg-yellow-50 text-pgBrand-200 ring-pgBrand-200`}
          >
            <div
              className={`rounded-full text-lg cursor-pointer`}
              onClick={() => {
                setStatusQuery(null); // Reset selected status
                setSelectedStatus(""); // Clear the selected status
                setOpen(true); // Open the dropdown to show all statuses
              }}
            >
              <HiX className="text-pgBrand-200 w-4 h-4" />
            </div>
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              <span className="text-sm font-light mr-1">{t("Status:")}</span>
              {statusQuery.name}
            </p>
          </span>
        )}

        <div className="relative inline-block" ref={dropdownRef}>
          {/* Button to trigger dropdown */}
          <button
            onClick={() => setOpen(!open)}
            className={`flex items-center text-base hover:cursor-pointer bg-none text-white hover:bg-none active:bg-none linear justify-center rounded-lg transition duration-200`}
          >
            <HiOutlineAdjustmentsHorizontal className="h-6 w-6 text-pgBrand-200" />
          </button>

          {/* Dropdown Menu with Animation */}
          <div
            className={`absolute z-50 mt-2 w-max rounded-xl bg-navy-700 py-3 px-4 shadow-xl transition-all duration-300 ease-in-out ${
              open ? "opacity-100 transform scale-100" : "opacity-0 transform scale-95"
            } origin-top-left`} // Change to origin-top-left
            style={{ pointerEvents: open ? "auto" : "none", left: "0", top: "100%" }} // Align to left and below the button
          >
            <h3 className="text-pgBrand-200 text-base px-3 mb-1">{t("Status")}</h3>
            <div>
              {uniqueStatuses.map((status) => (
                <li
                  key={status.id}
                  className={`flex items-center gap-4 text-base capitalize font-medium rounded-2xl px-3 py-1 bg-pgBrand-500 bg-opacity-0 hover:bg-opacity-20 ${
                    status.id === statusQuery?.id ? "bg-opacity-100" : ""
                  } dark:text-white cursor-pointer`}
                  onClick={() => {
                    setStatusQuery(status);
                    setSelectedStatus(status.id);
                    setOpen(false);
                  }}
                >
                  <span>{status.name}</span>
                </li>
              ))}
            </div>
          </div>
        </div>

        {hasPermission && (
          <button
            onClick={() => navigate("/admin/users/addUser")}
            className="linear group w-fit rounded-[20px] flex items-center space-x-3 bg-pgBrand-500 px-8 py-2 font-medium text-white  
            duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
            dark:hover:bg-pgBrand-550 dark:active:opacity-90"
          >
            <FaPlus className="group-hover:rotate-90 transition-transform duration-300" />
            <span className="uppercase tracking-wider text-sm font-tajawal">{t("Add User")}</span>
          </button>
        )}
      </div>


      </div>
    
      <div className="mt-8 h-full overflow-x-scroll overflow-y-scroll xl:overflow-hidden ">
        <table {...getTableProps()} className="w-full">
        <thead>
          <tr>
            <th className="border-b w-[5%] border-gray-200 pr-6 pb-[10px] text-start dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200">{t("#")}</p>
            </th>
            <th className="border-b w-[22%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200">{t("Name")}</p>
            </th>
            <th className="border-b w-[22%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200">{t("Email")}</p>
            </th>
            <th className="border-b w-[16%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200">{t("Phone")}</p>
            </th>
            <th className="border-b w-[16%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200">{t("Status")}</p>
            </th>
            <th className="border-b w-[16%] border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200">{t("Role")}</p>
            </th>
            <th className="border-b w-[8%]  border-gray-200pb-[10px] text-center dark:!border-navy-700">
              <p className="text-xs tracking-wide text-pgBrand-200 mx-auto">{t("Action")}</p>
            </th>
          </tr>
        </thead>

          <tbody>
            {data.map((user, index) => {
                const rowNumber = index + 1;
                return (
                <tr
                  className="h-14 border-b border-gray-100 border-opacity-10 "
                  key={user.id} // Changed from user.user_id to user.id
                >
                  <td className="w-[5%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-pgBrand-200">
                      {rowNumber}
                    </p>
                  </td>
                  <td className="w-[22%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {user.name ? user.name : "-"} {/* Updated field */}
                    </p>
                  </td>

                  <td className="w-[22%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {user.email ? user.email : "-"}
                    </p>
                  </td>
                  <td className="w-[16%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {user.phone ? user.phone : "-"}
                    </p>
                  </td>
                  <td className="w-[16%]">
                    <div className="flex items-center gap-2">
                      <div className={`rounded-full text-xl`}>
                        {user.status.id === 0 ? (
                          <IoInformationCircleOutline className="text-red-500" />
                        ) : user.status.id === 1 ? (
                          <FaRegCircleCheck className="text-green-500" />
                        ) : user.status.id === 2 ? (
                          <MdOutlineError className="text-orange-400" />
                        ) : user.status.id === 3 ? (
                          <MdOutlineCancel className="text-gray-400" />
                        ) : user.status.id === 4 ? (
                          <LuUserX2 className="text-red-500" />
                        ) : null}
                      </div>
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {user.status.name} {/* Updated field */}
                      </p>
                    </div>
                  </td>
                  <td className="w-[16%]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white flex space-x-1">
                      {user.roles
                        .sort((a, b) => b.id - a.id)
                        .map((role) => {
                          return (
                            <span
                              key={role.id}
                              className={`inline-flex  tracking-wide items-center rounded-xl
                              drop-shadow-sm bg-opacity-10 px-3 py-1 text-xs font-medium ring-1 ring-inset
                              ${
                                role.id === 1
                                  ? "bg-rose-100  text-rose-500  ring-rose-500"
                                  : role.id === 2
                                  ? "bg-yellow-50 text-pgBrand-200  ring-pgBrand-200"
                                  : ""
                              }`}
                            >
                              {role.name}
                            </span>
                          );
                        })}
                    </p>
                  </td>
                  <td className="w-[8%]">
                    {user.status.id !== 0 ? (
                      <Dropdown
                        button={
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent click from triggering other dropdowns
                              toggleActionDropdown(user.id); // Toggle this user's action dropdown
                            }}
                            open={actionDropdownOpen === user.id}
                            className={`flex items-center text-xl hover:cursor-pointer ${
                              transparent
                                ? "bg-none text-white hover:bg-none active:bg-none"
                                : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                            } linear justify-center rounded-lg font-bold transition duration-200`}
                          >
                            <BsThreeDots className="h-6 w-6" />
                          </button>
                        }
                        animation={"origin-top-right transition-all duration-300 ease-in-out"}
                        classNames={`${
                          transparent ? "top-8" : "top-11"
                        } right-0 w-max`}
                        children={
                          <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                            <p
                              onClick={() => {
                                getUser(user.user_id);
                              }}
                              className="font-tajawal hover:text-pgBrand-500 flex cursor-pointer items-center gap-2 text-gray-400 hover:font-medium"
                            >
                              <span>
                                <AiOutlineUser />
                              </span>
                              {t("View")}
                              </p>
{/*                             <p
                              onClick={() => archiveUser(user.email)}
                              className="hover:text-pgBrand-500 mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-400 hover:font-medium"
                            >
                              <span>
                                <FiArchive />
                              </span>
                              {/*{t("Archive")}
                            </p> */}
                            {user.status.id === 4 ? (
                              <p
                                onClick={() => banUser(user.user_id, user.status.id)} // Pass user ID and status ID for unbanning
                                className="font-tajawal hover:text-green-600 mt-2 flex cursor-pointer items-center gap-2 pt-1 text-green-500 hover:font-semibold font-medium"
                              >
                              {t("Unban")}
                              </p>
                            ) : (
                              <p
                                onClick={() => banUser(user.user_id, user.status.id)} // Pass user ID and status ID for banning
                                className="font-tajawal hover:text-red-600 mt-2 flex cursor-pointer items-center gap-2 pt-1 text-red-500 hover:font-semibold font-medium"
                              >
                                <span>
                                  <MdBlockFlipped />
                                </span>
                                {t("Ban")}
                              </p>
                            )}
                          </div>
                        }
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>

        </table>

        <Dialog
          size="lg"
          open={modalOpen}
          // handler={handleModalOpen}
          className="bg-navy-700 border border-pgBrand-200 text-center h-[85vh] overflow-y-scroll md:h-auto md:overflow-hidden   text-white rounded-xl"
        >
          <DialogHeader className="justify-between">
          {!modalEditable && hasEditPermission ? (
            <Button
              onClick={() => setModalEditable(!modalEditable)}
              className="linear group w-fit rounded-xl tracking-wider flex items-center space-x-3 bg-pgBrand-500 font-medium text-white  
              duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 px-6 py-2 lg:px-8 lg:py-2 text-xs dark:bg-pgBrand-500 
              dark:hover:bg-pgBrand-550 dark:active:opacity-90"
            >
              {t("Edit")}
            </Button>
          ) : (
            <div></div>
          )}
            <IconButton
              color="blue-gray"
              size="sm"
              variant="text"
              onClick={handleModalOpen}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </DialogHeader>
          <Formik initialValues={userProfile} onSubmit={updateUser}>
            <Form>
              <DialogBody>
                <div
                  className="relative mt-1 flex  w-full justify-center rounded-xl bg-cover bg-center px-4 
     "
                >
                  <div
                    className="  flex h-[150px] w-[150px] items-center justify-center
         rounded-full border-[8px] border-white  dark:!border-navy-900"
                  >
                    {profileImage ? (
                      <img
                        className="h-full z-50 w-full rounded-full object-cover"
                        src={profileImage}
                        alt=""
                      />
                    ) : (
                      <img
                        className="h-full z-50 w-full rounded-full object-cover"
                        src={placeholder}
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="w-full">
                  {modalEditable ? (
                    <div>
                      <div className="text-xl  font-bold text-navy-700 dark:text-white mt-2">
                        {t("Edit User Info")}
                      </div>
                      <div className="flex flex-col lg:flex-row gap-4 w-full justify-center mt-5">
                        <div className="relative w-full lg:w-1/3">
                          <input
                            required
                            id="first_name"
                            defaultValue={userData.first_name}
                            onChange={handleChange}
                            name="first_name"
                            className="block px-2.5 py-2 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          />
                          <label
                            htmlFor="first_name"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            {t("First Name")}
                          </label>
                        </div>
                        <div className="relative  w-full lg:w-1/3 ">
                          <input
                            required
                            id="last_name"
                            defaultValue={userData.last_name}
                            onChange={handleChange}
                            name="last_name"
                            className="block px-2.5 py-2 w-full  text-sm text-gray-900 bg-transparent 
                rounded-lg border border-pgBrand-200 appearance-none dark:text-white
              dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                            placeholder=""
                          />
                          <label
                            htmlFor="last_name"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            {t("Last Name")}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-10 w-full justify-center items-center flex flex-col px-4">
                      <div className="  px-4 text-right flex justify-center items-center space-x-2">
                        <div className={`rounded-full text-xl`}>
                          {userProfile.status?.id === 0 ? (
                            <IoInformationCircleOutline className="text-red-500" />
                          ) : userProfile.status?.id === 1 ? (
                            <FaRegCircleCheck className="text-green-500" />
                          ) : userProfile.status?.id === 2 ? (
                            <MdOutlineError className="text-orange-400" />
                          ) : userProfile.status?.id === 3 ? (
                            <MdOutlineCancel className="text-gray-400" />
                          ) : userProfile.status?.id === 4 ? (
                            <LuUserX2 className="text-red-500" />
                          ) : null}
                        </div>
                        <h4 className="text-2xl font-mono font-bold text-navy-700 dark:text-white">
                          {userData?.first_name
                            ? userData?.first_name
                            :<span className='text-sm'>[no name provided]</span> }
                          &nbsp;
                          {userData?.last_name}
                        </h4>
                      </div>
                    </div>
                  )}

                  {modalEditable ? (
                    <div className="flex flex-col lg:flex-row gap-4 w-full justify-center mt-5">
                      <div className="relative w-full lg:w-1/3">
                        <select
                          id="role"
                          name="role_ids"
                          required
                          onChange={(e) => {
                            const { value: id, text: name } =
                              e.target.selectedOptions[0];
                            const newRole = { id: parseInt(id), name };

                            if (
                              !userRoles.some(({ id }) => id === newRole.id)
                            ) {
                              setUserRoles([...userRoles, newRole]);
                            }
                          }}
                          className="block px-2.5 py-2 w-full text-sm text-gray-900 bg-transparent 
                                      rounded-lg border border-pgBrand-200 appearance-none dark:text-white dark:bg-navy-700
                                    dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
                                      focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                          placeholder=""
                        >
                          <option selected disabled>
                            {t("Select A Role")}
                          </option>
                          {roles
                            // .filter((role) => !newRoles.includes(role.name))
                            .map((role) => {
                              return (
                                <option value={role.id} key={role.id}>
                                  {role.name}
                                </option>
                              );
                            })}
                        </select>
                        <label
                          htmlFor="role"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          {t("Role")}
                        </label>
                      </div>
                      <div className="  w-full lg:w-1/3 ">
                        <div className="flex w-full space-x-2">
                          {userRoles
                            ? userRoles.map((sr) => {
                                return (
                                  <span
                                    key={sr.id}
                                    className={`flex justify-between w-fit space-x-3 tracking-wide items-center rounded-xl
                                        drop-shadow-sm bg-opacity-10 px-2 py-1
                                      text-xs font-medium  ring-1 ring-inset
                                      ${
                                        sr.id === 1
                                          ? "bg-rose-100  text-rose-500  ring-rose-500"
                                          : sr.id === 2
                                          ? "bg-yellow-50 text-pgBrand-200  ring-pgBrand-200"
                                          : ""
                                      }`}
                                  >
                                    <div
                                      className={`rounded-full text-lg cursor-pointer`}
                                      onClick={() => {
                                        const updatedRoles = userRoles.filter(
                                          (role) => role.id !== sr.id
                                        );
                                        setUserRoles(updatedRoles);
                                      }}
                                    >
                                      <HiX className="text-red-500" />
                                    </div>
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      {sr.name}
                                    </p>
                                  </span>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm mt-5 w-full justify-center font-bold text-navy-700 dark:text-white flex space-x-3">
                      {userRoles
                        ?.sort((a, b) => b.id - a.id)
                        .map((role) => {
                          return (
                            <span
                              key={role.id}
                              className={`inline-flex  tracking-wider items-center rounded-xl
                           drop-shadow-sm bg-opacity-10 px-3 py-1 text-xs font-medium ring-1 ring-inset
                           ${
                             role.id === 1
                               ? "bg-rose-100  text-rose-500  ring-rose-500"
                               : role.id === 2
                               ? "bg-yellow-50 text-pgBrand-200  ring-pgBrand-200"
                               : ""
                           }`}
                            >
                              {role.name}
                            </span>
                          );
                        })}
                    </p>
                  )}
                </div>
                {modalEditable ? (
                  <div className="text-sm mt-5 w-full justify-center font-bold text-navy-700 dark:text-white flex space-x-3">
                    {/* Status Select */}
                    <div className="relative w-full lg:w-1/3">
                      <select
                        id="status"
                        name="status"
                        required
                        onChange={handleChange}
                        className="block w-full px-4 py-2 text-sm bg-transparent border rounded-lg appearance-none dark:bg-navy-700 dark:text-white border-pgBrand-200 focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250"
                      >
                        <option disabled selected>Select A Status</option>
                        <option value={1} selected={userProfile.status.id === 1}>Active</option>
                        <option value={0} selected={userProfile.status.id === 0}>Inactive</option>
                        <option value={2} selected={userProfile.status.id === 2}>Not Verified</option>
                        <option value={3} selected={userProfile.status.id === 3}>Deactivated</option>
                        <option value={4} selected={userProfile.status.id === 4}>Banned</option>
                      </select>
                      <label
                          htmlFor="status"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          {t("Status")}
                      </label>
                    </div>
                    
                    {/* Phone Input */}
                    <div className="relative w-full lg:w-1/3">
                      <input
                        id="phone"
                        name="phone"
                        defaultValue={userProfile.phone}
                        onChange={handleChange}
                        className="block w-full px-4 py-2 text-sm bg-transparent border rounded-lg appearance-none dark:bg-navy-700 dark:text-white border-pgBrand-200 focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250"
                        placeholder=""
                      />
                      <label
                          htmlFor="phone"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  !bg-navy-700 px-2
                peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-pgBrand-200
                peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 
                peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                        {t("Phone Number")}
                      </label>
                    </div>
                  </div>
                ) : (
                    <div className="w-full lg:w-2/3 mx-auto mt-8 space-y-8">
                      {/* User Info Section */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex items-end space-x-3">
                          <p className="text-slate-200 tracking-wide font-tajawal">{t("Username")}:</p>
                          <p className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{userData ? userData.username : t("Name")}</p>
                        </div>
                        <div className="flex items-end space-x-3">
                          <p className="text-slate-200 tracking-wide font-tajawal">{t("Email")}:</p>
                          <p className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{userData ? userData.email : "email@test.com"}</p>
                        </div>
                      </div>

                      {/* Phone & Wallet Section */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex items-end space-x-3">
                          <p className="text-slate-200 tracking-wide font-tajawal">{t("Phone")}:</p>
                          <p className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{userProfile.phone ? userProfile.phone : "-"}</p>
                        </div>
                        <div className="flex items-end space-x-3">
                          <p className="text-slate-200 tracking-wide font-tajawal">{t("Wallet")}:</p>
                          <p className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{userData.wallet?.balance} <span className="ml-1 text-sm text-white">{userData.wallet?.currency}</span></p>
                        </div>
                      </div>

                      {/* Interests Section */}
                      <div className="flex items-end space-x-3">
                        <p className="text-slate-200 tracking-wide font-tajawal">{t("Interests")}:</p>
                        <div className="flex flex-wrap space-x-3">
                          {userInterests.length > 0 ? (
                            userInterests.map((interest) => (
                              <div key={interest.id} className="flex items-center space-x-2">
                                <img src={interest.icon} alt={interest.name} className="w-6 h-6" />
                                <span className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{interest.name}</span>
                              </div>
                            ))
                          ) : (
                            <span className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{t("No interests")}</span>
                          )}
                        </div>
                      </div>

                      {/* Favorite Facilities Section */}
                      <div className="flex items-end space-x-3">
                        <p className="text-slate-200 tracking-wide font-tajawal">{t("Favorite Facilities")}:</p>
                        <div className="flex flex-wrap space-x-3">
                          {favoriteFacilities.length > 0 ? (
                            favoriteFacilities.map((facility, index) => (
                              <div key={facility.id} className="flex items-center space-x-2">
                                <span className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{index + 1}. {facility.name}</span>
                                {index < favoriteFacilities.length - 1 && <span className="mx-2">|</span>}
                              </div>
                            ))
                          ) : (
                            <span className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{t("No favorite facilities available")}</span>
                          )}
                        </div>
                      </div>

                      {/* Favorite Playgrounds Section */}
                      <div className="flex items-end space-x-3">
                        <p className="text-slate-200 tracking-wide font-tajawal">{t("Favorite Playgrounds")}:</p>
                        <div className="flex flex-wrap space-x-3">
                          {favoritePlaygrounds.length > 0 ? (
                            favoritePlaygrounds.map((playground, index) => (
                              <div key={playground.id} className="flex items-center space-x-2">
                                <span className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{index + 1}. {playground.name}</span>
                                {index < favoritePlaygrounds.length - 1 && <span className="mx-2"></span>}
                              </div>
                            ))
                          ) : (
                            <span className="text-lg font-normal tracking-wider text-pgBrand-500 font-tajawal">{t("No favorite playgrounds available")}</span>
                          )}
                        </div>
                      </div>
                    </div>
                )}

              </DialogBody>
              <DialogFooter className="flex justify-between w-full  !mt-6">
                {modalEditable ? (
                  <>
                    <Button
                      variant="outlined"
                      color="red"
                      type="button"
                      onClick={() => setModalEditable(false)}
                      className="normal-case flex space-x-4 rounded-[20px] px-6 py-2 font-medium text-red-600 border-2 border-red-600 duration-200 text-sm dark:active:opacity-90 disabled:border-red-600 mr-1"
                    >
                      <span>{t("Cancel")}</span>
                    </Button>
                    <Button
                      loading={loading}
                      disabled={loading}
                      className="bg-pgBrand-500 normal-case flex space-x-4 rounded-[20px] px-6 py-2 font-mediumduration-200 text-sm mr-1"
                      type="submit"
                    >
                      <span>{t("Submit")}</span>
                    </Button>

                  </>
                ) : (
                  ""
                )}
              </DialogFooter>
            </Form>
          </Formik>
        </Dialog>
      </div>
    </Card>
  );
};

export default UsersTable;
