import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import LoadingCard from "../../../components/card/LoadingCard";
import { useTranslation } from "react-i18next"; // Import the translation hook

const Surveys = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false); // New state for permission
  const { t } = useTranslation(); // Initialize translation hook

  // Fetch surveys data from API
  useEffect(() => {
    axiosPrivate
      .get("/survey/survey-list/")
      .then((res) => {
        setSurveys(res.data.data); // Use 'data' key from the API response
        setHasPermission(res.data.has_permission); // Capture the permission value
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <div
          className="mb-4 mt-5 w-full px-2 py-2 flex flex-col rounded-[20px] h-fit md:h-[58px]
         justify-between md:flex-row md:items-center md:gap-1 xl:w-full xl:gap-2"
        >
          <div
            className="relative w-full md:w-3/4 3xl:w-2/3 flex h-full flex-grow items-center justify-start
           gap-2 bg-white shadow-xl shadow-shadow-500
            dark:bg-opacity-0 dark:shadow-none md:flex-grow-0"
          >
            {/* Input placeholder for future use */}
          </div>

          {/* Create survey button */}
          {hasPermission && (
            <button
              onClick={() => navigate("/admin/surveys/create")}
              className="linear group w-full mt-2 md:mt-0 text-center md:w-fit rounded-[20px] flex justify-center items-center
              space-x-3 bg-pgBrand-500 px-8 py-2 font-medium text-white
              duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500
              dark:hover:bg-pgBrand-550 dark:active:opacity-90"
            >
              <FaPlus className="group-hover:rotate-90 transition-transform duration-300" />
              <span className="uppercase tracking-wider text-sm">{t("Add Survey")}</span>
            </button>
          )}
        </div>

        {/* Surveys list or loading */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3 font-tajawal">
          {loading ? (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          ) : (
            surveys.map((survey) => (
              <div
                key={survey.id}
                onClick={() => navigate(`/admin/surveys/${survey.id}`)} // Navigate to survey details page
                className="p-4 bg-navy-800 border border-pgBrand-500 rounded-lg shadow-md cursor-pointer transition-transform hover:scale-105 font-tajawal"
              >
                <h2 className="text-xl font-bold font-tajawal text-white">{survey.name}</h2>
                <p className="mt-2 text-lg text-white font-tajawal">
                  {survey.questions.length > 0
                    ? `${survey.questions.length} questions available`
                    : "No questions available"}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Surveys;
