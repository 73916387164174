import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegEdit,
  FaRegMoneyBillAlt,
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
} from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { FiArchive } from "react-icons/fi";
import { HiOutlineMapPin, HiOutlineUserGroup } from "react-icons/hi2";
import Swal from "sweetalert2";
import { Button } from "@material-tailwind/react";
import dayjs from "dayjs";
import { t } from "i18next";

const PlaygroundDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { id } = useParams();

  const [playground, setPlayground] = useState({});
  const [coverImage, setCoverImage] = useState({});
  const [loading, setLoading] = useState(true);
  const [archiving, setArchiving] = useState(false);
  const [hasPermission, setHasPermission] = useState(false); // New state for permission

  // State for reviews
  const [reviewsData, setReviewsData] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  // State for matches
  const [matches, setMatches] = useState([]);
  const [matchesLoading, setMatchesLoading] = useState(true);

  useEffect(() => {
    getPlayground();
    fetchPlaygroundReviews();
    fetchUpcomingMatches();
  }, []);

  const getPlayground = () => {
    axiosPrivate
      .get(`/facility/${id}/playground-view/`)
      .then((res) => {
        setPlayground(res.data.data);
        setCoverImage(res.data.data.images.cover_image);
        setHasPermission(res.data.has_permission); // Capture and store the permission

      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchPlaygroundReviews = (page = 1) => {
    axiosPrivate
      .get(`/reviews/${id}/playground-reviews-list/?page=${page}`)
      .then((res) => {
        setReviewsData(res.data.data);
        setHasMore(res.data.has_more);
      })
      .catch((err) => {
        console.error("Error fetching reviews:", err);
      });
  };

  const fetchUpcomingMatches = () => {
    axiosPrivate
      .get(`/reservation/${id}/upcoming-match/`)
      .then((res) => {
        setMatches(res.data.data);
        setMatchesLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching matches:", err);
        setMatchesLoading(false);
      });
  };

  const archivePlayground = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F559FB",
      cancelButtonColor: "#adb5bd",
      confirmButtonText: "Archive Playground",
      background: "#091442",
      iconColor: "#F559FB",
      color: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate
          .patch(`/facility/${id}/delete-playground//`)
          .then((res) => {
            setArchiving(false);
            Swal.fire({
              icon: "success",
              title: "Playground has been archived successfully!",
              confirmButtonText: "ok",
              confirmButtonColor: "#34EDFF",
              background: "#091442",
              color: "white",
            });
            getPlayground();
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err.response.data.message,
              confirmButtonText: "Go Back",
              confirmButtonColor: "#45acc4",
              background: "#091442",
              color: "white",
            });
          });
      }
    });
  };

  function formatTime(time) {
    if (!time) return "";
    const dateObj = new Date(`2000-01-01T${time}`);
    return dateObj.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  // Helper function to render stars based on the rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      <div className="flex space-x-1">
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <FaStar key={index} className="text-pgBrand-200" />
          ))}
        {halfStar ? <FaStarHalfAlt className="text-pgBrand-200" /> : null}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FaRegStar key={index} className="text-pgBrand-200" />
          ))}
      </div>
    );
  };

  return (
    <Card extra={"items-center w-full h-full p-2 md:p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="w-full px-2 py-2 flex flex-col rounded-[20px]
         justify-between  lg:flex-row lg:items-center  md:gap-1 xl:w-full xl:gap-2"
      >
        <div
          className="relative w-full md:w-3/4 3xl:w-2/3 flex h-full  flex-grow items-center  justify-start
           gap-2 bg-white  shadow-xl shadow-shadow-500
            dark:bg-opacity-0 dark:shadow-none  md:flex-grow-0 "
        >
          <Button
            loading={archiving}
            disabled={archiving}
            onClick={() => archivePlayground()}
            className="linear group w-full mt-2 md:mt-0 text-center md:w-fit 
            rounded-[20px] flex justify-center items-center
             space-x-3  px-8 py-2  font-medium  
          duration-200  active:bg-gray-200 text-xs  dark:active:opacity-90 
          border dark:border-gray-300  text-gray-300 hover:text-pgBrand-500 hover:!border-pgBrand-500  hover:font-bold "
          >
            <FiArchive className=" " />
            <span className="uppercase tracking-wider text-xs ">Archive</span>
          </Button>
        </div>

        <div className="flex w-full mt-2 lg:mt-0 lg:w-1/2 justify-end flex-wrap md:flex-row gap-2">
        {hasPermission && (
          <button
            onClick={() => navigate(`/admin/playgrounds/editPlayground/${id}`)}
            className="linear group w-full mt-2 md:mt-0 text-center md:w-fit rounded-[20px] flex justify-center items-center
            space-x-3 bg-pgBrand-500 px-8 py-2 font-medium text-white  
            duration-200 hover:bg-pgBrand-550 active:bg-pgBrand-550 text-xs dark:bg-pgBrand-500 
            dark:hover:bg-pgBrand-550 dark:active:opacity-90"
          >
            <FaRegEdit />
            <span className="uppercase tracking-wider text-xs">{t("Edit Playground")}</span>
          </button>
        )}
        </div>
      </div>

      <div
        className="relative mt-1 flex h-48 md:h-96 w-full justify-center rounded-xl bg-cover bg-center
         bg-navy-700 bg-opacity-5 !duration-300 bg-blend-overlay"
        style={{ backgroundImage: `url('${coverImage}')` }}
      >
        <div className="mt-auto mb-10 flex items-center space-x-3 ">
          <h4 className="text-xl md:text-4xl font-tajawal tracking-wide drop-shadow-pgBrand3 text-navy-700 dark:text-white">
            {playground.name}
          </h4>
        </div>
        <div
          className="absolute md:-bottom-10 -bottom-44 md:flex flex-col grid grid-cols-1 lg:flex-row gap-2 
          md:space-x-9 h-fit lg:h-[65px] w-full md:w-5/6 3xl:w-4/6 items-center justify-center
         rounded-[18px] p-4 lg:rounded-full border-[4px] border-white bg-pgBrand-200 dark:!border-navy-800"
        >
          <div className="flex items-center space-x-3 px-3 py-1  justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <BsClockHistory className="w-5 h-5 text-navy-700" />
            <div className="flex space-x-2 justify-between items-center">
              <p className="w-fit text-base text-navy-700">
                {formatTime(playground.open)}
              </p>
              <p className="w-fit text-base text-navy-700"> - </p>
              <p className="w-fit text-base text-navy-700">
                {formatTime(playground.closed)}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 px-3 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <HiOutlineMapPin className="w-5 h-5 text-navy-700" />
            <div className=" flex space-x-3 justify-between items-center">
              <p className="w-fit text-base text-navy-700 tracking-wide">
                {playground.location?.address}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 px-3 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <FaRegMoneyBillAlt className="w-5 h-5 text-navy-700" />
            <div className=" flex space-x-1 justify-between items-center">
              <p className="w-fit text-base text-navy-700">
                {playground.price} {t("LYD")}
              </p>
              <p className="w-fit text-xs hidden md:block text-navy-700">
                / {t("Per Hour")}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 px-3 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <HiOutlineUserGroup className="w-5 h-5 text-navy-700" />
            <div className=" flex space-x-1 justify-between items-center">
              <p className="w-fit text-base text-navy-700">
                {playground.capacity} {t("Players")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Matches Section */}
      <div className="mt-10 w-full px-4">
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          {t("Today's Matches")}
        </h4>
        <div className="mt-4">
          {matchesLoading ? (
            <p className="text-gray-500">Loading matches...</p>
          ) : matches.length > 0 ? (
            matches.map((match) => (
              <div
                key={match.id}
                className="p-4 mb-2 bg-navy-900 text-white rounded-lg shadow-lg border border-pgBrand-500 "
              >
                <div className="flex justify-between items-center">
                  <p className="font-bold">{match.users.map(user => user.name).join(', ')}</p>
                  <p>{dayjs(match.date).format("MMMM D, YYYY")}</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                  <p className="text-sm text-gray-300">Start: {dayjs(match.start_time, "HH:mm:ss").format("h:mm A")}</p>
                  <p className="text-sm text-gray-300">End: {dayjs(match.end_time, "HH:mm:ss").format("h:mm A")}</p>
                </div>
                <p className="text-sm text-gray-300 mt-2">Status: {match.status.name}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No matches scheduled for today.</p>
          )}
        </div>
      </div>


      {/* Ratings and Reviews Section */}
      {reviewsData && (
        <div className="mt-8 w-full px-4">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            {t("Ratings & Reviews")}
          </h4>
          <div className="mt-4">
            <div className="flex items-center">
              <span className="text-3xl font-bold text-navy-700 dark:text-white">
                {reviewsData.average_rating.toFixed(1)}
              </span>
              <div className="ml-4">
                {renderStars(reviewsData.average_rating)}
              </div>
              <span className="ml-2 text-gray-500">
                ({reviewsData.total_reviews_count} reviews)
              </span>
            </div>
            <div className="mt-4 space-y-2">
              <div className="flex items-center">
                <span className="w-12 text-sm">{t("5 stars")}</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.five_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.five_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">{t("4 stars")}</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.four_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.four_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">{t("3 stars")}</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.three_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.three_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">{t("2 stars")}</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.two_stars_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.two_stars_percentage}%
                </span>
              </div>
              <div className="flex items-center">
                <span className="w-12 text-sm">{t("1 star")}</span>
                <div className="w-1/2 bg-gray-200 rounded-full h-4">
                  <div
                    className="bg-pgBrand-500 h-4 rounded-full"
                    style={{ width: `${reviewsData.one_star_percentage}%` }}
                  ></div>
                </div>
                <span className="ml-2 text-sm text-gray-500">
                  {reviewsData.one_star_percentage}%
                </span>
              </div>
            </div>
          </div>

          {/* Review List */}
          <div className="mt-8 grid grid-cols-1 gap-5 font-tajawal">
            <h5 className="text-lg font-bold text-navy-700 dark:text-white">
              {t("User Reviews")}
            </h5>
            {reviewsData.reviews.map((review) => (
              <div
                key={review.id}
                className="p-4 bg-navy-900 text-white rounded-lg shadow-lg border border-gray-600"
              >
                <div className="flex items-center">
                  {review.user.image ? (
                    <img
                      src={review.user.image}
                      alt={review.user.full_name}
                      className="w-10 h-10 rounded-full"
                    />
                  ) : (
                    <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
                  )}
                  <div className="ml-3">
                    <p className="font-bold">{review.user.full_name}</p>
                    <p className="text-gray-300">
                      {new Date(review.updated_at).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="mt-2">
                  <div className="mt-1">{renderStars(review.rating)}</div>
                  <p className="text-sm text-gray-300">{review.review}</p>
                </div>
              </div>
            ))}

            {/* Pagination Button */}
            {hasMore && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => fetchPlaygroundReviews()}
                  className="bg-pgBrand-500 text-white px-4 py-2 rounded-full"
                >
                  Load More Reviews
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default PlaygroundDetails;
