import axios, { axiosPrivate } from "../API/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { auth, setAuth } = useAuth();
  const refreshToken = localStorage.getItem("refresh_token");

  const refresh = async () => {
    const response = await axios.get("/auth/get_new_access_token/", {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });
    setAuth((prev) => {
     
      return {
        ...prev,
        access_token: response.data.data.access_token,
        roles: response.data.data.role.map((role) => role.name),
      };
    });
    return response.data.data.access_token;
  };
  return refresh;
};

export default useRefreshToken;
