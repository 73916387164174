import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../assets/img/svg2.svg";
import axios from "../../API/axios";

function ForgotPassword() {
  const navigate = useNavigate();
  const [step, setStep] = useState(2);

  const [countdown, setCountdown] = useState(60);
  const [activeButton, setActiveButton] = useState(false);

  const [credentials, setCredentials] = useState({
    email: "",
    verification_code: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    } else {
      setActiveButton(true);
    }
  }, [countdown]);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    setError(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (credentials.email === "")
      return setError({ error: true, message: "Please Enter Your Email" });
    else {
      setLoading(true);
      axios
        .post("/auth/forgot_password/", credentials, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // //  console.log(res.data.data.verify_otp_token);

          localStorage.setItem("resetToken", res.data.data.verify_otp_token);
          setStep(2);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setError({
            error: true,
            message: err.response.data.message,
          });
          setLoading(false);
        });
    }
  };

  const sendCode = (e) => {
    e.preventDefault();
    if (credentials.verification_code === "")
      return setError({ error: true, message: "Please Enter The Code" });
    else {
      const code = { verification_code: credentials.verification_code };
      setLoading(true);
      axios
        .post("/auth/verify_otp_code/", code, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("resetToken")}`,
          },
        })
        .then((res) => {
          //  console.log(res.data.data);
          localStorage.setItem(
            "resetToken",
            res.data.data.set_new_password_token
          );
          navigate("/ResetPassword");
        })
        .catch((err) => {
          console.error(err);
          setError({
            error: true,
            message: err.response.data.message,
          });
          setLoading(false);
        });
    }
  };

  const resendCode = () => {
    // e.preventDefault();
    setLoading(true);
    axios
      .post(
        "/auth/resend_otp_code_by_email/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("resetToken")}`,
          },
        }
      )
      .then((res) => {
        //  console.log(res.data.data);
        localStorage.setItem(
          "resetToken",
          res.data.data.set_new_password_token
        );
        setCountdown(60);
        setActiveButton(false);
      })
      .catch((err) => {
        console.error(err);
        setError({
          error: true,
          message: err.response.data.message,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className="flex z-10 h-screen w-full items-center absolute
     justify-center bg-cover bg-no-repeat  
       "
        style={{
          backgroundImage: `url(${Image})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="flex z-20 h-screen w-full items-center relative
     justify-center   
     "
      >
        <div
          className="flex items-center  relative z-20 md:rounded-3xl shadow-2xl h-screen  md:h-fit w-full  
           px-16 pt-20 pb-16 md:mx-auto md:max-w-lg  backdrop-blur-md border border-primary-300 bg-primary-400 bg-opacity-45
            border-secondary-100 border-opacity-50"
        >
          <div className="flex-1">
            <div className="text-center">
              <h2 className="text-4xl font-semibold text-center text-white">
                Mubarti
              </h2>
            </div>
            {step === 1 ? (
              <>
                <div className="text-center">
                  <p className="mt-2  text-sm text-slate-400 ">
                    Enter the code that was sent to your email address.
                  </p>
                </div>

                <div className="mt-6">
                  <form onSubmit={sendEmail}>
                    <div>
                      <label
                        htmlFor="Email"
                        className="block mb-2 text-xs text-white  tracking-widest"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        onChange={handleChange}
                        name="email"
                        id="email"
                        autoFocus="on"
                        // autoComplete="off"
                        placeholder="Your Email Address"
                        className={`block w-full text-sm px-4 py-2 mt-2 
                    placeholder-gray-400 bg-white 
                    rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                     focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
                     ${
                       error.error ? "ring ring-rose-600 border-rose-600" : ""
                     }`}
                      />
                    </div>

                    {error ? (
                      <div>
                        <p className="text-xs tracking-wider text-rose-500 font-semibold mt-4">
                          {error.message}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-7">
                      <button
                        type="submit"
                        disabled={loading}
                        className="w-full px-4 py-2 tracking-wide text-white font-medium shadow-sm transition-colors duration-200
                 transform bg-secondary-400 rounded-md hover:bg-secondary-100 focus:outline-none text-[14px]
                 disabled:bg-gray-400 focus:bg-secondary-400 focus:ring focus:ring-secondary-300 focus:ring-opacity-60"
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                  <p className="mt-6 text-sm text-center tracking-wider text-slate-300">
                    Back to{" "}
                    <span
                      className="text-pgBrand-200 cursor-pointer"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Login
                    </span>
                  </p>
                </div>
              </>
            ) : step === 2 ? (
              <>
                <div className="text-center">
                  <p className="mt-2  text-sm text-slate-400 ">
                    We have sent a verification code to &nbsp;
                    <span
                      className="font-medium text
                    text-black dark:text-white
                    "
                    >
                      {credentials?.email}
                    </span>
                    . Please enter the code below.
                  </p>
                </div>

                <div className="mt-6">
                  <form onSubmit={sendCode}>
                    <div>
                      <label
                        htmlFor="verification_code"
                        className="block mb-2 text-xs text-white  tracking-widest"
                      >
                        Code
                      </label>
                      <input
                        onChange={handleChange}
                        type="text"
                        name="verification_code"
                        id="verification_code"
                        placeholder="Verification Code"
                        autoFocus="off"
                        autoComplete="off"
                        className={`block w-full text-sm px-4 py-2 mt-2 
                    placeholder-gray-400 bg-white 
                    rounded-md  placeholder:text-gray-400m placeholder:text-xs placeholder:tracking-wider bg-opacity-10 border border-gray-400
                     focus:border-secondary-400  focus:ring-secondary-400 text-white  focus:outline-secondary-400 focus:ring focus:ring-opacity-60
                     ${error.error ? "ring ring-rose-600  border-error" : ""}`}
                      />
                    </div>

                    {error ? (
                      <div>
                        <p className="text-xs tracking-wider text-rose-500 font-semibold mt-4">
                          {error.message}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-7">
                      <button
                        type="submit"
                        // disabled={loading}
                        className="w-full text-sm px-4 py-2 tracking-wide text-white font-medium shadow-sm transition-colors duration-200
                 transform bg-secondary-400 rounded-md hover:bg-secondary-100 focus:outline-none
                 disabled:bg-gray-400 focus:bg-secondary-400 focus:ring focus:ring-secondary-300 focus:ring-opacity-60"
                      >
                        Continue
                      </button>
                    </div>
                  </form>

                  {/* <p className="mt-6 text-sm text-center  text-slate-400"> */}
                  {/* <p className="text-slate-300  text-sm tracking-wide font-medium ">
                      Didn&#x27;t get the email?
                    </p> */}

                  <button
                    onClick={() => resendCode()}
                    disabled={!activeButton}
                    className="mt-5 w-full px-4 py-2 tracking-wide text-pgBrand-200 font-medium
                     transition-colors duration-200 transform bg-transparent rounded-md hover:bg-secondary-100
                      focus:outline-none text-[12px] disabled:text-gray-400 focus:text-pgBrand-250 "
                  >
                    {countdown > 0 ? (
                      <>
                        <span className="">{countdown}</span>
                        <span className="ml-2">Resend Verification Code</span>
                      </>
                    ) : (
                      "Resend Verification Code"
                    )}
                  </button>
                  {/* </p> */}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
