import {
  columnsDataComplex,
} from "./variables/columnsData";
import Card from "../../../components/card";
import { useEffect, useState } from "react";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import UsersTable from "./components/UsersTable";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]); // New state for storing statuses
  const [selectedStatus, setSelectedStatus] = useState(""); // To store the selected status
  const [page, setPage] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const [hasMore, setHasMore] = useState(false); // State to track if more pages are available
  const [actionDropdownOpen, setActionDropdownOpen] = useState(null); // Track which user's action dropdown is open
  const [hasPermission, setHasPermission] = useState(false); // New state for permission

  // Fetch users based on current page and selected status
  const getUsers = () => {
    const statusFilter = selectedStatus !== "" ? `&status=${selectedStatus}` : ""; // Ensure we handle the case for 0
    axiosPrivate
      .get(`/manage/users-list?page=${page}&page_size=10${statusFilter}`) // Add status filter to the query
      .then((res) => {
        setUsers(res.data.data);
        setHasMore(res.data.has_more); // Store has_more from the API response
        setHasPermission(res.data.has_permission); // Capture and store has_permission
      })
      .catch((err) => {
        console.error(err);
      });
  };
  
  const toggleActionDropdown = (userId) => {
    setActionDropdownOpen((prev) => (prev === userId ? null : userId)); // Toggle the dropdown for the clicked user
  };

  // Fetch statuses from the new API
  const fetchStatuses = () => {
    axiosPrivate
      .get('/manage/get-player-status-list/')
      .then((res) => {
        if (res.data.status) {
          setStatuses(res.data.data); // Update the statuses state with fetched data
        } else {
          console.error("Failed to fetch statuses:", res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Trigger fetching users and statuses when component mounts
  useEffect(() => {
    fetchStatuses(); // Fetch statuses when the component mounts
    getUsers(); // Fetch users based on page and selected status
  }, [page, selectedStatus]);

  // Extract unique statuses from the fetched statuses
  const uniqueStatuses = [...new Set(statuses.map((status) => status.id))].map(
    (id) => statuses.find((status) => status.id === id)
  );

  // Ensure getUsers is called whenever selectedStatus changes
  useEffect(() => {
    getUsers();
  }, [selectedStatus]);

  return (
    <div>
      <Card exstra={"w-full"}></Card>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
        <UsersTable
          columnsData={columnsDataComplex}
          tableData={users}
          getUsers={() => getUsers()}
          uniqueStatuses={uniqueStatuses} // Pass unique statuses
          selectedStatus={selectedStatus} // Pass selected status
          setSelectedStatus={setSelectedStatus} // Pass setter for selected status
          toggleActionDropdown={toggleActionDropdown}
          actionDropdownOpen={actionDropdownOpen}
          hasPermission={hasPermission} // Pass permission as a prop to UsersTable

        />
        <div className="pagination-buttons mt-4 flex justify-between">
          <button
            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))} // Decrease page, but not less than 1
            className="bg-pgBrand-250 text-white p-3 rounded-md w-32 text-lg font-semibold"
            disabled={page === 1} // Disable the button if on the first page
          >
            Previous
          </button>
          <button
            onClick={() => setPage((prevPage) => prevPage + 1)} // Increase page
            className="bg-pgBrand-250 text-white p-3 rounded-md w-32 text-lg font-semibold"
            disabled={!hasMore} // Disable "Next" button if there are no more pages
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Users;
