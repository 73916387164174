import { useState } from "react";
import Card from ".";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import { PiDotsThreeBold, PiDotsThreeOutlineThin } from "react-icons/pi";

const LoadingCard = () => {
  return (
    <Card
      extra={
        "rounded-xl w-full flex cursor-pointer flex-col bg-white text-center shadow-lg p-5 overflow-hidden"
      }
    >
      <div className="h-full w-full">
        <div className="relative w-full ">
          <div className="mb-3  h-[250px] 3xl:h-[300px] w-full rounded-xl ">
            <div className="h-full w-full rounded-xl bg-slate-400"></div>
          </div>
        </div>

        <div className="mb-3 mt-6 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-1 w-full">
            <div className="relative flex w-2/3 animate-pulse gap-2">
              <div className="flex-1">
                {/* <div className="mb-1 h-5 w-3/5 rounded-lg bg-slate-300 text-lg"></div> */}
                <div className="h-5 w-[90%] rounded-lg bg-slate-300 text-sm"></div>
              </div>
            </div>


          </div>
        </div>
        <div className="mb-3 mt-6 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <div className="mb-1 w-full">
            <div className="relative flex w-1/2 animate-pulse gap-2">
              <div className="flex-1">
                {/* <div className="mb-1 h-5 w-3/5 rounded-lg bg-slate-300 text-lg"></div> */}
                <div className="h-5 w-[90%] rounded-lg bg-slate-300 text-sm"></div>
              </div>
            </div>


          </div>
        </div>
        

        <div className="flex justify-end mt-2">
          
        </div>
      </div>
    </Card>
  );
};

export default LoadingCard;
