import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../routes.js";
import Image from "../../assets/img/svg2.svg";



function index1({}) {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
          if (prop.layout === "/auth") {
            return (
              <Route path={`/${prop.path}`} element={prop.component} key={key} />
            );
          } else {
            return null;
          }
        });
      };
  return (
    <>
      <div
        className="flex z-10 h-screen w-full items-center absolute
     justify-center bg-cover bg-no-repeat  
       "
        style={{
          backgroundImage: `url(${Image})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="flex z-20 h-screen w-full items-center relative
     justify-center"
      >
        <div
          className="flex items-center  relative z-20 md:rounded-3xl shadow-2xl h-screen  md:h-fit w-full  
         px-16 py-20 md:mx-auto md:max-w-lg  backdrop-blur-md border border-primary-300 bg-primary-400 bg-opacity-45
          border-secondary-100 border-opacity-50"
        >
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default index1;
