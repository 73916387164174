import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import Datepicker from "react-tailwindcss-datepicker";
import Card from "../../../../components/card";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Button } from "@material-tailwind/react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

function EditAvailableHours() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [availability, setAvailability] = useState({});
  const [availabilityID, setAvailabilityID] = useState("");

  const daysOfWeek = availability?.days_of_week?.map((day) => parseInt(day.id));

  const [days, setDays] = useState([]);
  const [playground, setPlayground] = useState({});
  const [slot, setSlot] = useState(0);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [dateValue, setDateValue] = useState({
    start_date: "",
    end_date: "",
  });
  const [changedValues, setChangedValues] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangedValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const startHour = parseInt(playground?.open?.split(":")[0]);
  const startMinute = parseInt(playground?.open?.split(":")[1]);
  const endHour = parseInt(playground?.closed?.split(":")[0]);
  const endMinute = parseInt(playground?.closed?.split(":")[1]);
  var time = dayjs().set("hour", startHour).set("minute", startMinute);
  var timeSlots = [];

  while (
    time.isSameOrBefore(dayjs().set("hour", endHour).set("minute", endMinute))
  ) {
    const formattedTime = time.format("HH:mm");
    timeSlots.push(formattedTime);
    time = time.add(slot, "minute");
  }

  const getPlayground = () => {
    axiosPrivate
      .get(`/facility/${id}/playground-view/`)
      .then((res) => {
        //  console.log(res.data.data);
        setPlayground(res.data.data);
        setSlot(res.data.data.time_slot);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPlaygroundAvailability = () => {
    axiosPrivate
      .get(`/facility/playground/available-hours/${id}/view/`)
      .then((res) => {
        // //  console.log(res.data);
        setAvailability(res.data);
        setDateValue({
          start_date: res.data.start_date,
          end_date: res.data.end_date,
        });
        setStartTime(res.data.start_time);
        setEndTime(res.data.end_time);
        setDays(res.data.days_of_week.map((day) => day.id));
        setAvailabilityID(res.data.id && res.data.id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getPlayground();
    getPlaygroundAvailability();
    setPageLoading(false);
  }, []);

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
    // //  console.log(startTime);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };
  const handleDateValueChange = (newValue) => {
    // //  console.log("newValue:", dateValue);
    setDateValue({
      start_date: newValue.startDate,
      end_date: newValue.endDate,
    });
    setChangedValues((prevFormValues) => ({
      ...prevFormValues,
      start_date: newValue.startDate,
      end_date: newValue.endDate,
    }));
  };

  const handleDaysChange = (value, checked) => {
    const intValue = parseInt(value);
    setDays((prevDays) => {
      if (checked) {
        // setChangedValues((prevFormValues) => ({
        //   ...prevFormValues,
        //   days_of_week: [...prevDays, intValue],
        // }));
        return [...prevDays, intValue];
      } else {
        // setChangedValues((prevFormValues) => ({
        //   ...prevFormValues,
        //   days_of_week: prevDays.filter((day) => day !== intValue),
        // }));
        return prevDays.filter((day) => day !== intValue);
      }
    });

    // //  console.log("days:" + days);
  };

  const submit = (data) => {
    setLoading(true);
    let formdata = {
      playground: id,
      start_date: dateValue.start_date,
      end_date: dateValue.end_date,
      days_of_week: days,
      start_time: startTime,
      end_time: endTime,
    };

    let updateValues = {
      ...changedValues,
      days_of_week: days,
    };
    if (availabilityID) {
      axiosPrivate
        .patch(
          `/facility/playground/available-hours/${availabilityID}/update/`,
          updateValues
        )
        .then((res) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Available hours has been updated successfully!",
            confirmButtonText: "Ok",
            confirmButtonColor: "#34EDFF",
            background: "#091442",
            color: "white",
          }).then((response) => {
            navigate(`/admin/playgrounds/${playground.id}`);
          });
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Something Went Wrong!",
            text: err.response.data.message,
            confirmButtonText: "Go Back",
            confirmButtonColor: "#34EDFF",
            background: "#091442",
            color: "white",
          });
        });
    } else {
      axiosPrivate
        .post("/facility/playground/available-hours/add/", formdata)
        .then((res) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Available hours has been updated successfully!",
            confirmButtonText: "Ok",
            confirmButtonColor: "#34EDFF",
            background: "#091442",
            color: "white",
          }).then((response) => {
            navigate(`/admin/playgrounds/${playground.id}`);
          });
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Something Went Wrong!",
            text: err.response.data.message,
            confirmButtonText: "Go Back",
            confirmButtonColor: "#34EDFF",
            background: "#091442",
            color: "white",
          });
        });
    }

    //  console.log(updateValues);
  };

  function formatTime(time) {
    if (!time) return ""; // Handle case when time is not available

    const dateObj = new Date(`2000-01-01T${time}`);
    const formattedTime = dateObj.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  }

  return (
    <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
      {pageLoading ? (
        ""
      ) : (
        <Card
          extra={"w-full h-full md:min-h-[70vh] flex p-6 sm:overflow-x-auto"}
        >
          <div className="flex items-center justify-between w-full lg:pr-8 text-base  mb-4">
            <div className="flex items-center w-full">
              <h2 className=" w-full lg:min-w-[25%] lg:!w-fit text-2xl text-pgBrand-500 text-left">
                {playground.name}
              </h2>
              <div
                className={`h-[2px] w-full   
                
                    bg-pgBrand-500
                  
              `}
              ></div>
            </div>
          </div>
          <Formik onSubmit={submit} initialValues={{}}>
            <Form>
              <div
                className="pt-5 mb-5  border-gray-200 dark:border-gray-700 
    flex sm:flex-row flex-col sm:space-x-5 justify-between rtl:space-x-reverse"
              >
                <div className="mx-auto sm:mx-0 w-full lg:w-1/3">
                  <h2 className="text-lg text-gray-900 dark:text-white font-medium mb-4 ">
                    Select Duration
                  </h2>
                  <Datepicker
                    value={{
                      startDate: dateValue.start_date,
                      endDate: dateValue.end_date,
                    }}
                    separator={"To"}
                    onChange={handleDateValueChange}
                    inputClassName="w-full rounded-md focus:ring-1 border 
          border-pgBrand-400  font-normal dark:text-pgBrand-200
           px-2.5 pb-3 pt-4 w-full  text-sm  bg-transparent 
          rounded-lg border border-pgBrand-200 appearance-none 
        dark:border-pgBrand-200 dark:focus:border-pgBrand-200 
          focus:outline-none focus:ring-1 focus:border-pgBrand-200 focus:ring-pgBrand-250 peer"
                  />

                  <h2 className="text-lg text-gray-900 dark:text-white font-medium mt-6 mb-3">
                    Every:
                  </h2>
                  <ul id="start_time" className="grid w-full grid-cols-2 gap-3">
                    <li>
                      <input
                        type="checkbox"
                        id="everyday"
                        value={0}
                        defaultChecked={daysOfWeek?.includes(0)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="everyday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Everyday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="saturday"
                        value={1}
                        defaultChecked={daysOfWeek?.includes(1)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="saturday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Saturday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="sunday"
                        value={2}
                        className="hidden peer"
                        defaultChecked={daysOfWeek?.includes(2)}
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="sunday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Sunday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="monday"
                        value={3}
                        defaultChecked={daysOfWeek?.includes(3)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="monday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Monday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="tuesday"
                        value={4}
                        defaultChecked={daysOfWeek?.includes(4)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="tuesday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Tuesday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="wednesday"
                        value={5}
                        defaultChecked={daysOfWeek?.includes(5)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="wednesday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Wednesday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="thursday"
                        value={6}
                        defaultChecked={daysOfWeek?.includes(6)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="thursday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Tursday
                      </label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="friday"
                        value={7}
                        defaultdefaultChecked={daysOfWeek?.includes(7)}
                        className="hidden peer"
                        onChange={(e) =>
                          handleDaysChange(e.target.value, e.target.checked)
                        }
                      />
                      <label
                        htmlFor="friday"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-pgBrand-250 border-pgBrand-250
                dark:hover:text-white dark:border-pgBrand-200
                 dark:peer-checked:border-pgBrand-200
                  peer-checked:border-pgBrand-250 peer-checked:bg-pgBrand-250 
                  hover:text-white peer-checked:text-gray-900 hover:bg-pgBrand-200
                   dark:text-pgBrand-200 dark:bg-gray-900 dark:hover:bg-pgBrand-250
                    dark:hover:border-pgBrand-250 dark:peer-checked:bg-pgBrand-200"
                      >
                        Friday
                      </label>
                    </li>
                  </ul>
                </div>
                <div
                  className="sm:ms-7 sm:ps-5 sm:border-s 
         border-gray-200 dark:border-gray-800 
       w-full  sm:w-1/3 mt-5 sm:mt-0 "
                >
                  <div className="mx-auto w-full md:w-4/5">
                    <h2 className="text-lg capitalize text-gray-900 dark:text-white font-medium ">
                      Pick the OPENNING time
                    </h2>

                    <ul
                      id="start_time"
                      className="grid w-full grid-cols-3 gap-3 mt-6"
                    >
                      {timeSlots?.map((ts, index) => {
                        let uIndex = index + Math.random();
                        return (
                          <li key={index}>
                            <input
                              type="radio"
                              id={uIndex}
                              defaultChecked={
                                startTime === `${ts}:00` ? true : false
                              }
                              value={`${ts}:00`}
                              className="hidden peer"
                              onChange={(e) => {
                                handleStartTimeChange(e);
                                handleChange(e);
                              }}
                              name="start_time"
                            />
                            <label
                              htmlFor={uIndex}
                              className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-gray-500 border-gray-500
                dark:hover:text-white dark:border-gray-500
                 dark:peer-checked:border-pgBrand-500
                  peer-checked:border-pgBrand-550 peer-checked:bg-pgBrand-550 
                  hover:text-white peer-checked:text-white hover:bg-pgBrand-500
                    dark:bg-gray-900 dark:hover:bg-pgBrand-550
                    dark:hover:border-pgBrand-550 dark:peer-checked:bg-pgBrand-500"
                            >
                              {formatTime(ts)}
                            </label>
                          </li>
                        );
                      })}

                      {/* <li>
                      <input
                        type="radio"
                        id="s2-am"
                        value="02:00:00"
                        className="hidden peer"
                        onChange={handleStartTimeChange}
                        name="start_time"
                      />
                      <label
                        htmlFor="s2-am"
                        className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-gray-500 border-gray-500
                dark:hover:text-white dark:border-gray-500
                 dark:peer-checked:border-pgBrand-500
                  peer-checked:border-pgBrand-550 peer-checked:bg-pgBrand-550 
                  hover:text-white peer-checked:text-white hover:bg-pgBrand-500
                    dark:bg-gray-900 dark:hover:bg-pgBrand-550
                    dark:hover:border-pgBrand-550 dark:peer-checked:bg-pgBrand-500"
                      >
                        02:00 AM
                      </label>
                    </li>  */}
                    </ul>
                  </div>
                </div>

                <div
                  className="sm:ms-7 sm:ps-5 sm:border-s 
         border-gray-200 dark:border-gray-800 
       w-full  sm:w-1/3 mt-5 sm:mt-0"
                >
                  <div className="mx-auto w-full md:w-4/5">
                    <h2 className="text-lg text-gray-900 capitalize dark:text-white font-medium ">
                      Pick the CLOSING time
                    </h2>
                    <ul
                      id="end_time"
                      className="grid w-full grid-cols-3 gap-3 mt-6"
                    >
                      {timeSlots?.map((ts, index) => {
                        let uIndex = index * Math.random();
                        return (
                          <li key={index}>
                            <input
                              type="radio"
                              id={uIndex}
                              defaultChecked={
                                endTime === `${ts}:00` ? true : false
                              }
                              value={`${ts}:00`}
                              className="hidden peer"
                              onChange={(e) => {
                                handleEndTimeChange(e);
                                handleChange(e);
                              }}
                              name="end_time"
                            />
                            <label
                              htmlFor={uIndex}
                              className="inline-flex items-center justify-center w-full p-2 
              text-sm font-medium text-center bg-white border rounded-lg
               cursor-pointer text-gray-500 border-gray-500
                dark:hover:text-white dark:border-gray-500
                 dark:peer-checked:border-pgBrand-500
                  peer-checked:border-pgBrand-550 peer-checked:bg-pgBrand-550 
                  hover:text-white peer-checked:text-white hover:bg-pgBrand-500
                    dark:bg-gray-900 dark:hover:bg-pgBrand-550
                    dark:hover:border-pgBrand-550 dark:peer-checked:bg-pgBrand-500"
                            >
                              {formatTime(ts)}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex justify-end w-full mt-8  lg:absolute bottom-8 right-10">
                <Button
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  className="linear  flex justify-between rounded-[20px] bg-pgBrand-500 px-12 py-2  font-medium text-white  
                     duration-200  hover:bg-pgBrand-550 active:bg-pgBrand-550 text-sm dark:bg-pgBrand-500 
                  dark:hover:bg-pgBrand-550 dark:active:opacity-90 disabled:bg-gray-400"
                >
                  submit
                </Button>
              </div>
            </Form>
          </Formik>
        </Card>
      )}
    </div>
  );
}

export default EditAvailableHours;
