import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import SVG from "react-inlinesvg";
import { HiOutlineMapPin, HiOutlineUserGroup } from "react-icons/hi2";

const UserDetails = () => {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [profileImage, setProfileImage] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosPrivate
      .get(`/manage/${id}/view-user/`)
      .then((res) => {
        //  console.log(res.data.data);
        setUserProfile(res.data.data.profile);
        setUserRoles(res.data.data.profile.roles);
        setProfileImage(res.data.data.profile.image);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function formatTime(time) {
    if (!time) return ""; // Handle case when time is not available

    const dateObj = new Date(`2000-01-01T${time}`);
    const formattedTime = dateObj.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  }

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      {/* <div
        className="relative mt-1 flex h-96 w-full justify-center rounded-xl bg-cover bg-center"
        style={{ backgroundImage: `url('${coverImage}')` }}
      >
        <div
          className="absolute -bottom-10 flex flex-col lg:flex-row space-x-9 h-[65px] w-full md:w-5/6 3xl:w-4/6 items-center justify-center
         rounded-full border-[4px] border-white bg-pgBrand-200 dark:!border-navy-800"
        >
          <div className="flex items-center space-x-3 px-3 py-1  justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <BsClockHistory className="w-5 h-5 text-navy-700" />
            <div className="flex space-x-2 justify-between items-center">
              <p className="w-fit text-base text-navy-700">
                {formatTime(user.open)}
              </p>
              <p className="w-fit text-base text-navy-700"> - </p>
              <p className="w-fit text-base text-navy-700">
                {formatTime(user.closed)}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 px-3 py-1 justify-between rounded-2xl border   border-navy-700 border-opacity-65">
            <HiOutlineMapPin className="w-5 h-5 text-navy-700" />
            <div className=" flex space-x-3 justify-between items-center">
              <p className="w-fit text-base text-navy-700 tracking-wide">
                {user.location?.address}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-3 px-3 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <FaRegMoneyBillAlt className="w-5 h-5 text-navy-700" />
            <div className=" flex space-x-1 justify-between items-center">
              <p className="w-fit text-base text-navy-700">
                {user.price} LYD
              </p>
              <p className="w-fit text-xs text-navy-700">/ Per Hour</p>
            </div>
          </div>
          <div className="flex items-center space-x-3 px-3 py-1 justify-between rounded-2xl border border-navy-700 border-opacity-65">
            <HiOutlineUserGroup className="w-5 h-5 text-navy-700" />
            <div className=" flex space-x-1 justify-between items-center">
              <p className="w-fit text-base text-navy-700">
                {user.capacity} Players
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="relative mt-1 flex h-24 w-full justify-center rounded-xl bg-cover bg-center px-4 
     "
      >
        <div
          className="absolute -bottom-32 flex h-[200px] w-[200px] items-center justify-center
         rounded-full border-[8px] border-white  dark:!border-navy-900"
        >
          <img
            className="h-full z-50 w-full rounded-full object-cover"
            src={profileImage}
            alt=""
          />
        </div>
      </div>

      {/* Name and position */}
      <div className="w-full">
        <div className="mt-40 w-full justify-center items-center flex flex-col sm:flex-row   px-4">
          <div className="border-r w-1/2 px-4 text-right">
            <h4 className="text-2xl font-mono font-bold text-navy-700 dark:text-white">
              {userProfile.name}
            </h4>
          </div>
          <div className=" w-1/2 px-4">
            <p className="text-2xl font-normal tracking-wider text-pgBrand-500">
              {userProfile.wallet?.balance}
              <span className="ml-1 text-sm text-white">
                {userProfile.wallet?.currency}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Post followers */}
      {/* <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">17</p>
          <p className="text-sm font-normal text-gray-600">Posts</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            9.7K
          </p>
          <p className="text-sm font-normal text-gray-600">Followers</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            434
          </p>
          <p className="text-sm font-normal text-gray-600">Following</p>
        </div>
      </div> */}
    </Card>
  );
};

export default UserDetails;
